import styled from '@emotion/styled'
import React from 'react'
import { TextKeys } from 'tiger/libs/TextRepository.ts'
import { AccountStatusGet, BackOfficeAccountGetDto } from '../../api/swagger/definitions/backoffice'
import ButtonIconChangeStatus from '../../figma/images/buttonIconChangeStatus.tsx'
import ColorsDeprecated from '../../figma/tokens/ColorsDeprecated.ts'
import Spacings from '../../figma/tokens/Spacings.ts'
import { BorderContainer, BorderContainerHeader } from '../../helpers/CreditOnCardHelpers.tsx'
import FigmaBox from '../../mynt-components/components/FigmaBox.tsx'
import { UnstyledButton } from '../../mynt-components/components/StyledComponents.tsx'
import CustomerCardTabOverviewDataRow from './CustomerCardTabOverviewDataRow.tsx'
import TextContainer from '../../components/TextContainer.tsx'
import { TooltipChangeAccountStatus } from '../../components/Tooltips.tsx'
import useConfigByCountry from 'flamingo/hooks/useConfigByCountry.ts'
import { colors } from 'themes'

interface CustomerCardTabOverviewAccountProps {
  customerId: string
  cardAccount: BackOfficeAccountGetDto
  setIsChangeStatusModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CustomerCardTabOverviewAccount: React.FC<CustomerCardTabOverviewAccountProps> = ({
  customerId,
  cardAccount,
  setIsChangeStatusModalOpen
}) => {
  const { formatAmount } = useConfigByCountry()

  return (
    <BorderContainer fullWidth>
      <BorderContainerHeader noBorder>
        <TextContainer textKey={TextKeys.customersCardOverviewEnfuseAcccountHeading} />
        <FigmaBox direction="row" align="center" gap={Spacings.min}>
          <StatusLabel status={cardAccount.status}>{cardAccount.status}</StatusLabel>
          <TooltipChangeAccountStatus
            content={
              <UnstyledButton onClick={() => setIsChangeStatusModalOpen(true)}>
                <ButtonIconChangeStatus />
              </UnstyledButton>
            }
          />
        </FigmaBox>
      </BorderContainerHeader>
      <FigmaBox fullWidth justify="space-around" direction="row" gap={Spacings.tiny} bottom={Spacings.large}>
        <ValueContainer fullWidth>
          <TextContainer textKey={TextKeys.customersCardOverviewAccountSettledBalanceLabel} />
          <TextContainer
            text={formatAmount(cardAccount.balance)}
            textKey={TextKeys.overviewCorporateCustomerColumnCustomerRepresentative}
          />
        </ValueContainer>
        <ValueContainer fullWidth>
          <TextContainer textKey={TextKeys.customersCardOverviewAccountAvailableToSpendLable} />
          <TextContainer
            text={formatAmount(cardAccount.available)}
            textKey={TextKeys.overviewCorporateCustomerColumnCustomerRepresentative}
          />
        </ValueContainer>
        <ValueContainer fullWidth>
          <TextContainer textKey={TextKeys.customersCardOverviewAccountCreditLimitLable} />
          <TextContainer
            text={formatAmount(cardAccount.creditLimit)}
            textKey={TextKeys.overviewCorporateCustomerColumnCustomerRepresentative}
          />
        </ValueContainer>
      </FigmaBox>
      <FigmaBox fullWidth direction="row" justify="space-between" gap={Spacings.small} bottom={Spacings.medium}>
        <FigmaBox fullWidth gap={Spacings.min}>
          <CustomerCardTabOverviewDataRow name="Name" value="Card Account" />
          <CustomerCardTabOverviewDataRow name="Enfuce id" value={cardAccount.externalId} />
        </FigmaBox>
        <FigmaBox fullWidth gap={Spacings.min}>
          <CustomerCardTabOverviewDataRow name="Type" value={cardAccount.type} />
          <CustomerCardTabOverviewDataRow name="Template" value={cardAccount.accountTemplate} />
        </FigmaBox>
      </FigmaBox>
      <FigmaBox fullWidth gap={Spacings.min}>
        <CustomerCardTabOverviewDataRow name="Number" value={cardAccount.number} />
        <CustomerCardTabOverviewDataRow name="Mynt internal id" value={cardAccount.id} />
        <CustomerCardTabOverviewDataRow name="Customer id" value={customerId} />
      </FigmaBox>
    </BorderContainer>
  )
}

const ValueContainer = styled(FigmaBox)`
  flex-direction: column;
  justify-content: space-around;
  padding: ${Spacings.tiny};
  border: solid 1px ${ColorsDeprecated.baseStone};
  border-radius: ${Spacings.min};
`

const StatusLabel = styled(FigmaBox)<{ status: AccountStatusGet }>`
  padding: 2px 8px;
  background-color: ${(props) => (props.status === AccountStatusGet.OK ? colors.product.success[600] : colors.product.error[600])};
  border-radius: ${Spacings.min};
  color: white;
`

export default CustomerCardTabOverviewAccount
