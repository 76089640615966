import { TabSets } from 'helpers/TabsHelpers'
import { useNavigate, useParams } from 'react-router'
import { CardCreditDataState, CardPartner, CustomerFeature, CustomerProduct, KycAccessLevel } from '../api/swagger/definitions/backoffice'
import EtaskuLogo from '../figma/svgs/EtaskuLogo'
import Finland24 from '../figma/svgs/Finland24'
import FortnoxLogo from '../figma/svgs/FortnoxLogo'
import PEAccountingLogo from '../figma/svgs/PEAccountingLogo'
import Sweden24 from '../figma/svgs/Sweden24'
import WintLogo from '../figma/svgs/WintLogo'
import Spacings from '../figma/tokens/Spacings'
import { Countries } from '../flamingo/config/ConfigCountries'
import { AccountStatuses } from '../helpers/AccountStatusHelper'
import {
  useActivationGuide,
  useActivationGuideVisibility,
  useBillingSettings,
  useCardCredits,
  useCustomer,
  usePatchCustomer
} from '../api/react-query'
import { prettifyEnumLabel } from '../helpers/CreditOnCardHelpers'
import useMaterialNotification from '../hooks/useMaterialNotification'
import TextKeys from '../libs/TextKeys'
import { BO_MAX_CONTENT_WIDTH } from '../mynt-components/WeakHardCodedSizes'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { LightTooltip } from '../mynt-components/components/MyntTooltip'
import SVGContainer from '../mynt-components/components/SVGContainer'
import Tabs from '../mynt-components/components/Tabs'
import AccountStatus from './AccountStatus'
import { CardPartnerBadge } from './CardPartnerBadge'
import CreditsTabs from './CreditsTabs'
import CustomerCardTab from '../pages/CustomerCardTab/CustomerCardTab.tsx'
import CustomerDetailsPep from './CustomerDetailsPep'
import CustomerGeneralForm from './CustomerFormGeneralContainer'
import { CustomerPeople } from './CustomerPeople'
import CustomerScoringTabs from './CustomerScoringTabs'
import DirectDebitTab from './DirectDebitTab'
import TextContainer from './TextContainer'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { colors } from 'themes'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import CircularProgress from '@mui/material/CircularProgress'
import FindityLogo from 'figma/svgs/FindityLogo'
import { useIsLoadingWithPermission, usePermissions } from 'contexts/permissions'
import { useMemo } from 'react'
import { CustomerNotes, useCustomerNotesDataProvider } from './CustomerNotes'
import { useNotesContext } from 'contexts/Notes'
import dayjs from 'dayjs'
import Colors from 'figma/panda/Colors'
import FeatherIcon from 'feather-icons-react'
import Typography from '@mui/material/Typography'
import { CustomerBureauPanel } from './CustomerBureauPanel'
import { CustomerPaymentWhitelist } from 'pages/CustomerPaymentWhitelist/CustomerPaymentWhitelist'
import DenmarkFlag from '../figma/svgs/DenmarkFlag.tsx'

const copy = async (text: string) => window.navigator.clipboard.writeText(text)

const CountryToIcon = {
  [Countries.SE]: Sweden24,
  [Countries.FI]: Finland24,
  [Countries.DK]: DenmarkFlag
}

const PartnerStyles = (partner: CardPartner) => {
  if (partner === CardPartner.NONE) return null

  switch (partner) {
    case CardPartner.PE: {
      return {
        logo: <PEAccountingLogo height={22} width="auto" />,
        color: 'rgb(7, 34, 50)',
        fill: 'white',
        stroke: 'white'
      }
    }

    case CardPartner.WINT: {
      return {
        logo: <WintLogo height={22} width="auto" />,
        color: '#0bc2f9',
        fill: 'white'
      }
    }

    case CardPartner.FORTNOX: {
      return {
        logo: <FortnoxLogo height={16} width="auto" />,
        color: 'rgb(0, 37, 23)',
        fill: 'white'
      }
    }

    case CardPartner.ETASKU: {
      return {
        logo: <EtaskuLogo height={18} width="auto" />,
        color: '#F9F9F9'
      }
    }

    case CardPartner.FINDITY: {
      return {
        logo: <FindityLogo height={18} width="auto" />,
        color: '#012a2d'
      }
    }

    default: {
      return {
        logo: (
          <span>
            missing partner logo {partner},{' '}
            <a target="_BLANK" href="slack://channel?team=TBEGNHMN0&id=C05QKJQ2G4F" rel="noreferrer">
              let us know
            </a>
          </span>
        ),
        color: ''
      }
    }
  }
}

const PartnerDisconnectedBadge = styled.div<{ color: string; fill?: string; stroke?: string }>`
  padding: ${Spacings.minimum} ${Spacings.tiny};
  border-radius: 8px;
  background-color: ${(props) => props.color};
  color: #1c1b1f;
  cursor: default;
  font-size: 14px;

  path {
    fill: ${(props) => props.fill};
    stroke: ${(props) => props.stroke};
  }
`

const PartnerDisconnected = ({ visible }) => {
  if (!visible) return null

  return (
    <PartnerDisconnectedBadge color={colors.product.error[600]}>
      <Tooltip title="This customer has been removed by the card partner">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'center' }}>
          <RemoveCircleIcon style={{ fontSize: 18 }} />
          <span>Disconnected by partner</span>
        </Box>
      </Tooltip>
    </PartnerDisconnectedBadge>
  )
}

const CustomerDetailsContainer = () => {
  const permissions = {
    activationGuide: usePermissions('activation-guide.status'),
    activationGuideVisibility: usePermissions('activation-guide.visibility'),
    cardCredits: usePermissions('customers.cards.credits.view'),
    subscriptionPlan: usePermissions('subscription-plan'),
    people: usePermissions('customers.people.view'),
    scoring: usePermissions('customers.scoring'),
    kyc: usePermissions('customers.kyc'),
    directDebit: usePermissions('customers.direct-debit'),
    card: usePermissions('customers.card'),
    cardTransactions: usePermissions('customers.cards.transactions.view'),
    credits: usePermissions('customers.credits'),
    rollingCredit: usePermissions('rolling-credit'),
    bureau: usePermissions('customers.bureau'),
    whitelist: usePermissions('customers.bureau')
  }

  const { customerId } = useParams<'customerId'>() as Record<string, string>
  const navigate = useNavigate()
  const mutation = usePatchCustomer(customerId)
  const { data: activationGuide } = useActivationGuide(customerId, { enabled: permissions.activationGuide })
  const { data: cardCredits } = useCardCredits(customerId, { enabled: permissions.cardCredits })

  const customerNotesProps = useCustomerNotesDataProvider(customerId, { enabled: true })
  const [notesContext] = useNotesContext()

  const { isLoading: customerIsLoading, data: customer } = useCustomer(customerId, {
    retry: false,
    refetchOnWindowFocus: false
  })

  const { data: activationGuideVisibility, isLoading: _activationGuideVisibilityIsLoading } = useActivationGuideVisibility(customerId, {
    enabled: permissions.activationGuideVisibility
  })

  const { data: subscriptionPlan, isLoading: _isLoadingSubscriptionPlan } = useBillingSettings(customerId, {
    enabled: permissions.subscriptionPlan
  })

  const activationGuideVisibilityIsLoading = useIsLoadingWithPermission(_activationGuideVisibilityIsLoading, 'activation-guide.visibility')
  const isLoadingSubscriptionPlan = useIsLoadingWithPermission(_isLoadingSubscriptionPlan, 'subscription-plan')

  const notification = useMaterialNotification()

  const features = useMemo(() => Array.from(customer?.features || []), [customer])
  const products = useMemo(() => Array.from(customer?.products || []), [customer])

  const isLoading = [customerIsLoading, activationGuideVisibilityIsLoading, isLoadingSubscriptionPlan].some(Boolean)

  // If we have issues with customer not loading, because of subscriptionPlan, move this check to the subscriptionPlan dropdown.
  if (isLoading || !customer) {
    return <CircularProgress sx={{ display: 'block', margin: '50px auto' }} />
  }

  const hasCardProduct = products.includes(CustomerProduct.CARD)
  const hasCardCredit = products.includes(CustomerProduct.CARD_CREDIT)
  const hasCompanyCredit = products.includes(CustomerProduct.COMPANY_CREDIT)
  const hasCardTopup = features.includes(CustomerFeature.TOPUP)
  const hasOrderCard = features.includes(CustomerFeature.CARD_ORDER)

  const hasApprovedCardCredit = !!cardCredits && cardCredits.some((cardCredit) => cardCredit?.state === CardCreditDataState.APPROVED)

  const statuses: AccountStatuses = {
    products: [hasCardProduct ? KycAccessLevel.GREEN : KycAccessLevel.GREY, hasCompanyCredit ? KycAccessLevel.GREEN : KycAccessLevel.GREY],
    kyc: [activationGuide?.statuses.kyc || KycAccessLevel.GREY, customer.kycAccessLevel] as KycAccessLevel[],
    cardFeatures: [
      hasOrderCard ? KycAccessLevel.GREEN : KycAccessLevel.GREY,
      hasCardTopup ? KycAccessLevel.GREEN : KycAccessLevel.GREY,
      hasCardCredit ? KycAccessLevel.GREEN : KycAccessLevel.GREY
    ],
    cardActivationStatus: [
      activationGuide?.statuses.addMembersAndOrderCards || KycAccessLevel.GREY,
      activationGuide?.statuses.connectAcountingSystem || KycAccessLevel.GREY,
      hasApprovedCardCredit ? KycAccessLevel.GREEN : KycAccessLevel.GREY,
      activationGuide?.statuses.addMoney || KycAccessLevel.GREY,
      activationGuide?.statuses.madePurchase || KycAccessLevel.GREY
    ] as KycAccessLevel[]
  }

  const copyCompanyName = () => {
    const companyName = customer.companyName || '-'
    notification(`Copied name '${companyName}'`, 'info')
    copy(companyName)
  }

  const copyOrgNo = () => {
    const orgNo = customer.orgNo || '-'
    notification(`Copied orgNo '${orgNo}'`, 'info')
    copy(orgNo)
  }

  const copyCustomerId = () => {
    const id = customer.id || '-'
    notification(`Copied customer id '${id}'`, 'info')
    copy(id)
  }

  const flag = CountryToIcon[customer.country]
  const partnerStyle = PartnerStyles(customer.cardPartner)
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: `100%`, maxWidth: `${BO_MAX_CONTENT_WIDTH}px`, padding: '0 32px' }}>
        <CustomerActivationTime isActivated={customer.isActivated} activationDate={customer.activateTransactionsAt} />
        {/* <div style={{ width: `calc(100% - ${BO_NOTES_MAX_WIDTH}px)`, maxWidth: `${BO_MAX_CONTENT_WIDTH}px`, padding: '0 32px' }}> */}
        <FigmaBox align="baseline" fullWidth direction="row" justify="space-between">
          <FigmaBox fullWidth>
            <FigmaBox fullWidth direction="row" gap={Spacings.tiny} align="center">
              <FigmaBox
                direction="row"
                gap={Spacings.tiny}
                spacing={Spacings.minimum}
                bottom
                style={{ cursor: 'pointer' }}
                onClick={copyCompanyName}
                align="center"
              >
                <TextContainer textKey={TextKeys.companyNameHeading} text={customer.companyName} />
              </FigmaBox>
              {flag && <SVGContainer SVG={flag} />}
              {partnerStyle && (
                <>
                  <LightTooltip
                    title={`${prettifyEnumLabel(customer.cardPartner)} card partner`}
                    content={
                      <CardPartnerBadge
                        logo={partnerStyle.logo}
                        svgStyles={{ fill: partnerStyle.fill, stroke: partnerStyle.stroke }}
                        color={partnerStyle.color}
                      />
                    }
                  />
                </>
              )}
              <PartnerDisconnected visible={customer.partnerDisconnected} />
            </FigmaBox>
            <FigmaBox bottom={Spacings.tiny} style={{ cursor: 'pointer' }} onClick={copyCustomerId}>
              <TextContainer textKey={TextKeys.companyOrgNum} text={customer.id || '-'} />
            </FigmaBox>
            <FigmaBox style={{ cursor: 'pointer' }} onClick={copyOrgNo}>
              <TextContainer textKey={TextKeys.companyOrgNum} text={customer.orgNo || '-'} />
            </FigmaBox>
          </FigmaBox>

          <AccountStatus customer={customer} statuses={statuses} />
        </FigmaBox>

        <Tabs
          fullPage
          enableNestedTabs
          alwaysShowNavHeadings
          tabKeyNameSpace={TabSets.CUSTOMER}
          tabs={[
            {
              labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'General'} />,
              contentElement: (
                <CustomerGeneralForm
                  activationGuideVisibility={activationGuideVisibility}
                  customer={customer}
                  customerMutation={(partialCustomer) => mutation.mutateAsync(partialCustomer)}
                  billingSettingsPricePlan={subscriptionPlan?.pricePlanType}
                />
              ),
              enable: true
            },
            {
              labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'People'} />,
              contentElement: (
                <Box sx={{ maxWidth: notesContext.open ? '75vw' : '93vw' }}>
                  <CustomerPeople customerId={customer.id} />
                </Box>
              ),
              enable: permissions.people
            },
            {
              labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Scoring files'} />,
              contentElement: <CustomerScoringTabs customerId={customer.id} />,
              enable: permissions.scoring
            },
            {
              labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'KYC'} />,
              contentElement: <CustomerDetailsPep customerId={customer.id} />,
              enable: permissions.kyc
            },
            {
              labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Direct debit'} />,
              contentElement: <DirectDebitTab customerId={customer.id} />,
              enable: permissions.directDebit
            },
            {
              labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Card'} />,
              contentElement: (
                <CustomerCardTab
                  customerId={customer.id}
                  customer={customer}
                  patchCustomerDetails={async (data) => mutation.mutateAsync(data)}
                />
              ),
              enable: permissions.card || permissions.cardTransactions
            },
            {
              labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Credits'} />,
              contentElement: <CreditsTabs customerId={customer.id} />,
              enable: permissions.credits
            },
            {
              labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Rolling credit'} />,
              contentElement: <></>,
              enable: permissions.rollingCredit,
              onClick: () => navigate(`/rolling-credit?customerId=${customerId}`)
            },
            {
              labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Bureau'} />,
              contentElement: <CustomerBureauPanel customerId={customer.id} />,
              enable: permissions.bureau
            },
            {
              labelElement: <TextContainer textKey={TextKeys.tabNotSelected} text={'Payment Whitelist'} />,
              contentElement: <CustomerPaymentWhitelist customerId={customer.id} />,
              enable: permissions.whitelist
            }
          ]}
        />
      </div>
      <CustomerNotes {...customerNotesProps} />
    </div>
  )
}

const CustomerActivationTime = ({ isActivated, activationDate }) => {
  if (isActivated) return null

  return (
    <Tooltip
      title={
        <Typography variant="subtitle1" textAlign="center">
          The customer account can be activated up to one hour later than the time specified
        </Typography>
      }
      placement="top"
    >
      <Box
        sx={{
          padding: '4px 8px',
          display: 'flex',
          alignItems: 'center',
          color: 'black',
          borderRadius: '8px',
          gap: '4px',
          backgroundColor: Colors.alert300,
          width: 'fit-content',
          marginBottom: '16px'
        }}
      >
        <FeatherIcon icon="alert-triangle" size={16} />
        Account activation scheduled at {dayjs(activationDate).utc(true).tz('Europe/Stockholm').format('YYYY-MM-DD HH:mm')}*
      </Box>
    </Tooltip>
  )
}

export default CustomerDetailsContainer
