/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BillingSettings } from '../models';
// @ts-ignore
import { BillingSettingsFeatureName } from '../models';
// @ts-ignore
import { BillingSettingsFeatureOverridePatch } from '../models';
// @ts-ignore
import { BillingSettingsFieldName } from '../models';
// @ts-ignore
import { BillingSettingsOverridePatch } from '../models';
// @ts-ignore
import { BillingSettingsTrialOverridePatch } from '../models';
// @ts-ignore
import { ErrorMessage } from '../models';
/**
 * BillingSettingsApi - axios parameter creator
 * @export
 */
export const BillingSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSettings: async (customerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('billingSettings', 'customerId', customerId)
            const localVarPath = `/bo/v1/customers/{customer-id}/billing-settings`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFeatureName} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureOverride: async (customerId: string, featureName: BillingSettingsFeatureName, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteFeatureOverride', 'customerId', customerId)
            // verify required parameter 'featureName' is not null or undefined
            assertParamExists('deleteFeatureOverride', 'featureName', featureName)
            const localVarPath = `/bo/v1/customers/{customer-id}/billing-settings/overrides/features/{feature-name}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"feature-name"}}`, encodeURIComponent(String(featureName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFieldName} fieldName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOverrides: async (customerId: string, fieldName: BillingSettingsFieldName, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteOverrides', 'customerId', customerId)
            // verify required parameter 'fieldName' is not null or undefined
            assertParamExists('deleteOverrides', 'fieldName', fieldName)
            const localVarPath = `/bo/v1/customers/{customer-id}/billing-settings/overrides/{field-name}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"field-name"}}`, encodeURIComponent(String(fieldName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFeatureOverridePatch} billingSettingsFeatureOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFeatureOverrides: async (customerId: string, billingSettingsFeatureOverridePatch: BillingSettingsFeatureOverridePatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchFeatureOverrides', 'customerId', customerId)
            // verify required parameter 'billingSettingsFeatureOverridePatch' is not null or undefined
            assertParamExists('patchFeatureOverrides', 'billingSettingsFeatureOverridePatch', billingSettingsFeatureOverridePatch)
            const localVarPath = `/bo/v1/customers/{customer-id}/billing-settings/overrides/features`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingSettingsFeatureOverridePatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsOverridePatch} billingSettingsOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOverrides: async (customerId: string, billingSettingsOverridePatch: BillingSettingsOverridePatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchOverrides', 'customerId', customerId)
            // verify required parameter 'billingSettingsOverridePatch' is not null or undefined
            assertParamExists('patchOverrides', 'billingSettingsOverridePatch', billingSettingsOverridePatch)
            const localVarPath = `/bo/v1/customers/{customer-id}/billing-settings/overrides`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingSettingsOverridePatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsTrialOverridePatch} billingSettingsTrialOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTrialOverrides: async (customerId: string, billingSettingsTrialOverridePatch: BillingSettingsTrialOverridePatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('patchTrialOverrides', 'customerId', customerId)
            // verify required parameter 'billingSettingsTrialOverridePatch' is not null or undefined
            assertParamExists('patchTrialOverrides', 'billingSettingsTrialOverridePatch', billingSettingsTrialOverridePatch)
            const localVarPath = `/bo/v1/customers/{customer-id}/billing-settings/overrides/trials`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingSettingsTrialOverridePatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingSettingsApi - functional programming interface
 * @export
 */
export const BillingSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingSettings(customerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingSettings(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFeatureName} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFeatureOverride(customerId: string, featureName: BillingSettingsFeatureName, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFeatureOverride(customerId, featureName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFieldName} fieldName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOverrides(customerId: string, fieldName: BillingSettingsFieldName, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOverrides(customerId, fieldName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFeatureOverridePatch} billingSettingsFeatureOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchFeatureOverrides(customerId: string, billingSettingsFeatureOverridePatch: BillingSettingsFeatureOverridePatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchFeatureOverrides(customerId, billingSettingsFeatureOverridePatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsOverridePatch} billingSettingsOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchOverrides(customerId: string, billingSettingsOverridePatch: BillingSettingsOverridePatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchOverrides(customerId, billingSettingsOverridePatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsTrialOverridePatch} billingSettingsTrialOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchTrialOverrides(customerId: string, billingSettingsTrialOverridePatch: BillingSettingsTrialOverridePatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchTrialOverrides(customerId, billingSettingsTrialOverridePatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingSettingsApi - factory interface
 * @export
 */
export const BillingSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingSettingsApiFp(configuration)
    return {
        /**
         * hasBackOfficeFullOrSalesRole()
         * @param {string} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSettings(customerId: string, options?: any): AxiosPromise<BillingSettings> {
            return localVarFp.billingSettings(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFeatureName} featureName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureOverride(customerId: string, featureName: BillingSettingsFeatureName, options?: any): AxiosPromise<BillingSettings> {
            return localVarFp.deleteFeatureOverride(customerId, featureName, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFieldName} fieldName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOverrides(customerId: string, fieldName: BillingSettingsFieldName, options?: any): AxiosPromise<BillingSettings> {
            return localVarFp.deleteOverrides(customerId, fieldName, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsFeatureOverridePatch} billingSettingsFeatureOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchFeatureOverrides(customerId: string, billingSettingsFeatureOverridePatch: BillingSettingsFeatureOverridePatch, options?: any): AxiosPromise<BillingSettings> {
            return localVarFp.patchFeatureOverrides(customerId, billingSettingsFeatureOverridePatch, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsOverridePatch} billingSettingsOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOverrides(customerId: string, billingSettingsOverridePatch: BillingSettingsOverridePatch, options?: any): AxiosPromise<BillingSettings> {
            return localVarFp.patchOverrides(customerId, billingSettingsOverridePatch, options).then((request) => request(axios, basePath));
        },
        /**
         * hasBackOfficeFullRole()
         * @param {string} customerId 
         * @param {BillingSettingsTrialOverridePatch} billingSettingsTrialOverridePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchTrialOverrides(customerId: string, billingSettingsTrialOverridePatch: BillingSettingsTrialOverridePatch, options?: any): AxiosPromise<BillingSettings> {
            return localVarFp.patchTrialOverrides(customerId, billingSettingsTrialOverridePatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingSettingsApi - object-oriented interface
 * @export
 * @class BillingSettingsApi
 * @extends {BaseAPI}
 */
export class BillingSettingsApi extends BaseAPI {
    /**
     * hasBackOfficeFullOrSalesRole()
     * @param {string} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingSettingsApi
     */
    public billingSettings(customerId: string, options?: AxiosRequestConfig) {
        return BillingSettingsApiFp(this.configuration).billingSettings(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BillingSettingsFeatureName} featureName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingSettingsApi
     */
    public deleteFeatureOverride(customerId: string, featureName: BillingSettingsFeatureName, options?: AxiosRequestConfig) {
        return BillingSettingsApiFp(this.configuration).deleteFeatureOverride(customerId, featureName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BillingSettingsFieldName} fieldName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingSettingsApi
     */
    public deleteOverrides(customerId: string, fieldName: BillingSettingsFieldName, options?: AxiosRequestConfig) {
        return BillingSettingsApiFp(this.configuration).deleteOverrides(customerId, fieldName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BillingSettingsFeatureOverridePatch} billingSettingsFeatureOverridePatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingSettingsApi
     */
    public patchFeatureOverrides(customerId: string, billingSettingsFeatureOverridePatch: BillingSettingsFeatureOverridePatch, options?: AxiosRequestConfig) {
        return BillingSettingsApiFp(this.configuration).patchFeatureOverrides(customerId, billingSettingsFeatureOverridePatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BillingSettingsOverridePatch} billingSettingsOverridePatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingSettingsApi
     */
    public patchOverrides(customerId: string, billingSettingsOverridePatch: BillingSettingsOverridePatch, options?: AxiosRequestConfig) {
        return BillingSettingsApiFp(this.configuration).patchOverrides(customerId, billingSettingsOverridePatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * hasBackOfficeFullRole()
     * @param {string} customerId 
     * @param {BillingSettingsTrialOverridePatch} billingSettingsTrialOverridePatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingSettingsApi
     */
    public patchTrialOverrides(customerId: string, billingSettingsTrialOverridePatch: BillingSettingsTrialOverridePatch, options?: AxiosRequestConfig) {
        return BillingSettingsApiFp(this.configuration).patchTrialOverrides(customerId, billingSettingsTrialOverridePatch, options).then((request) => request(this.axios, this.basePath));
    }
}
