import TextContainer from 'components/TextContainer'
import TextKeys from 'libs/TextKeys'
import { SwitchController, TextFieldController } from 'components/react-hook-components'
import { SubmitHandler, useFormContext } from 'react-hook-form'
import Box from '@mui/material/Box'
import type { BillingSettingsFormData } from './BillingSettingsForm'
import React from 'react'
import Typography from '@mui/material/Typography'
import Colors from 'figma/panda/Colors'
import { OverrideSettingComponent } from './OverrideSettingComponent'
import { SettingsContainer } from './SettingsContainer'
import Stack from '@mui/material/Stack'
import { LightTooltip } from 'mynt-components/components/MyntTooltip'
import IconInfo24 from 'figma/svgs/IconInfo24'
import SVGContainer from 'mynt-components/components/SVGContainer'
import { BillingSettingsFieldName, BillingSettingsOverridePatch } from 'api/swagger/definitions/backoffice/models'

type UsersSettingsProps = {
  isLoading: boolean
  customerId: string
  handleReset: (field: BillingSettingsFieldName) => void
  handleUpdate: (body: BillingSettingsOverridePatch) => void
}

const UsersSettings = ({ isLoading, handleReset, handleUpdate }: UsersSettingsProps) => {
  const { watch } = useFormContext<BillingSettingsFormData>()

  const planType = watch('userSettings.type')
  const isUnlimited = watch('userSettings.type') === 'UNLIMITED_USERS'

  return (
    <SettingsContainer title="User fees">
      <Stack spacing={3}>
        <NumberOfIncludedUsers
          isLoading={isLoading}
          disabled={isLoading || isUnlimited}
          handleReset={handleReset}
          handleUpdate={handleUpdate}
        />
        {planType === 'PREMIUM' && (
          <PricePerAdditionalUsers
            isLoading={isLoading}
            disabled={isLoading || isUnlimited}
            handleReset={handleReset}
            handleUpdate={handleUpdate}
          />
        )}
        <UnlimitedUsers isLoading={isLoading} disabled={isLoading} handleReset={handleReset} handleUpdate={handleUpdate} />
      </Stack>
    </SettingsContainer>
  )
}

export default UsersSettings

interface FieldProps {
  isLoading: boolean
  disabled: boolean
  handleReset: (field: BillingSettingsFieldName) => void
  handleUpdate: SubmitHandler<BillingSettingsOverridePatch>
}

const NumberOfIncludedUsers = ({ isLoading, disabled, handleReset, handleUpdate }: FieldProps) => {
  const { watch, handleSubmit, control } = useFormContext<BillingSettingsFormData>()
  const isNumberOfUsersOverride = watch('userSettings.numberOfIncludedUsers.isOverride')
  const defaultNumberOfIncludedUsers = watch('userSettings.numberOfIncludedUsers.originalValue')

  const onClickReset = () => {
    handleReset('INCLUDED_USERS')
  }

  const onUpdate = (data: BillingSettingsFormData) => {
    const numberOfUsers = data.payload.numberOfUsers
    if (numberOfUsers === defaultNumberOfIncludedUsers) {
      onClickReset()
    } else {
      handleUpdate({ numberOfUsers })
    }
  }

  return (
    <Box sx={{ display: 'flex', width: '100%', gap: 3, alignItems: 'center' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <TextContainer text={'Number of free users'} textKey={TextKeys.billingSettingsRecurringFeesSubscriptionFee} />
        {isNumberOfUsersOverride && (
          <OverrideSettingComponent defaultValue={`${defaultNumberOfIncludedUsers}`} onClickResetOverride={onClickReset} />
        )}
      </Box>
      <TextFieldController
        name="payload.numberOfUsers"
        loading={isLoading}
        disabled={disabled}
        labelText={'Number of free users'}
        control={control}
        type="number"
        onBlur={handleSubmit(onUpdate)}
        adornment={<></>}
      />
    </Box>
  )
}

const PricePerAdditionalUsers = ({ isLoading, disabled, handleUpdate, handleReset }: FieldProps) => {
  const { watch, handleSubmit, control } = useFormContext<BillingSettingsFormData>()

  const pricePerAdditionalUserOverride = watch('userSettings.pricePerAdditionalUser.isOverride')
  const pricePerAdditionalUser = watch('userSettings.pricePerAdditionalUser')
  const currency = watch('userSettings.pricePerAdditionalUser.value.currency')

  const onClickReset = () => {
    handleReset('PRICE_PER_ADDITIONAL_USER')
  }

  const onUpdate = (data: BillingSettingsFormData) => {
    if (data.payload.pricePerAdditionalUser === pricePerAdditionalUser.originalValue?.value) {
      onClickReset()
    } else {
      handleUpdate({ pricePerAdditionalUser: data.payload.pricePerAdditionalUser })
    }
  }

  return (
    <Box sx={{ display: 'flex', width: '100%', gap: 3, alignItems: 'center' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <TextContainer text={'Price per user'} textKey={TextKeys.billingSettingsRecurringFeesSubscriptionFee} />
        {pricePerAdditionalUserOverride && (
          <OverrideSettingComponent
            defaultValue={`${pricePerAdditionalUser.originalValue?.value} ${currency}`}
            onClickResetOverride={onClickReset}
          />
        )}
      </Box>
      <TextFieldController
        name="payload.pricePerAdditionalUser"
        loading={isLoading}
        disabled={disabled}
        labelText={'Price per user'}
        control={control}
        type="number"
        onBlur={handleSubmit(onUpdate)}
        adornment={<span>{currency}</span>}
      />
    </Box>
  )
}

const UnlimitedUsers = ({ isLoading, disabled, handleUpdate }: FieldProps) => {
  const { handleSubmit, control } = useFormContext<BillingSettingsFormData>()

  const onUpdate = (data: BillingSettingsFormData) => {
    handleUpdate({ isUnlimitedUsers: data.payload.isUnlimitedUsers })
  }

  return (
    <Box sx={{ display: 'flex', width: '100%', gap: 3, alignItems: 'center' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <LightTooltip
          title={'By enabling unlimited users, there is no limit on the amount of free users that can be added'}
          placement="top"
          content={
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Typography sx={{ color: Colors.baseBlack }} variant="subtitle1">
                Unlimited
              </Typography>
              <SVGContainer SVG={IconInfo24} svgColor={Colors.base500} svgStyles={{ width: 16, height: 16 }} />
            </Box>
          }
        />
      </Box>
      <SwitchController
        disabled={disabled || isLoading}
        onChange={handleSubmit(onUpdate)}
        control={control}
        name="payload.isUnlimitedUsers"
      />
    </Box>
  )
}
