import { BillingSettingsApiFactory } from 'api/swagger/definitions/backoffice/api'
import config from 'config'
import Axios from 'api/axios'
import {
  BillingSettingsFeatureName,
  BillingSettingsFeatureOverridePatch,
  BillingSettingsFieldName,
  BillingSettingsOverridePatch,
  BillingSettingsTrialOverridePatch
} from 'api/swagger/definitions/backoffice/models'

const billingSettingsApi = BillingSettingsApiFactory(undefined, config.boBaseUrl, Axios)

export const getBillingSettings = async (customerId: string) => billingSettingsApi.billingSettings(customerId).then(({ data }) => data)

export const updateBillingSettings = {
  resetSettings: (customerId: string, field: BillingSettingsFieldName) => billingSettingsApi.deleteOverrides(customerId, field),
  updateSettings: (customerId: string, body: BillingSettingsOverridePatch) =>
    billingSettingsApi.patchOverrides(customerId, body).then(({ data }) => data),
  resetFeature: (customerId: string, feature: BillingSettingsFeatureName) =>
    billingSettingsApi.deleteFeatureOverride(customerId, feature).then(({ data }) => data),
  patchFeature: (customerId: string, body: BillingSettingsFeatureOverridePatch) =>
    billingSettingsApi.patchFeatureOverrides(customerId, body).then(({ data }) => data),
  patchTrial: (customerId: string, trial: BillingSettingsTrialOverridePatch) =>
    billingSettingsApi.patchTrialOverrides(customerId, trial).then(({ data }) => data)
}
