import { formatCurrency } from 'helpers/utils.ts'
import React, { useEffect } from 'react'
import { UsageLimit } from '../../api/swagger/definitions/backoffice'
import Box from '@mui/material/Box'
import useMaterialNotification from '../../hooks/useMaterialNotification.tsx'
import { usePatchCardV1 } from '../../api/react-query'
import { NumberFormatController } from '../../components/react-hook-components.tsx'
import { useForm } from 'react-hook-form'
import { DataGridPro } from '@mui/x-data-grid-pro'
import Typography from '@mui/material/Typography'

interface CustomerCardTabOverviewAccountUsageLimitsProps {
  usageLimits: UsageLimit[]
  showPlaceholders?: boolean
  internalCardId: string
  singleAmountLimit?: number
  customerId: string
  isLoading?: boolean
}

const CustomerCardTabOverviewAccountUsageLimits: React.FC<CustomerCardTabOverviewAccountUsageLimitsProps> = ({
  usageLimits,
  showPlaceholders = false,
  singleAmountLimit,
  internalCardId,
  customerId
}) => {
  const notify = useMaterialNotification()
  const mutation = usePatchCardV1(customerId, internalCardId)

  const form = useForm({
    defaultValues: {
      singleAmountLimit,
      dailySumAmount: 0
    }
  })

  useEffect(() => {
    if (!singleAmountLimit) return

    form.reset({ singleAmountLimit })
  }, [singleAmountLimit])

  if (usageLimits?.length === 0) return null

  const handleChangeSingleAmountLimit = (payload: Partial<{ singleAmountLimit: number; dailySumAmount: number }>) => {
    mutation.mutateAsync(payload).then(() => {
      payload.singleAmountLimit && notify(`Updated single amount limit to ${payload.singleAmountLimit}`)
      payload.dailySumAmount && notify(`Updated 24hr sum amount to ${payload.dailySumAmount}`)
    })
  }

  return (
    <>
      <Box sx={{ padding: '24px 0', width: 'fit-content', flexDirection: 'row', display: 'flex', gap: '8px' }}>
        <NumberFormatController
          loading={mutation.isLoading}
          disabled={mutation.isLoading}
          control={form.control}
          name="singleAmountLimit"
          onBlur={form.handleSubmit(({ singleAmountLimit }) => handleChangeSingleAmountLimit({ singleAmountLimit }))}
          labelText="Single amount limit"
        />
        <NumberFormatController
          loading={mutation.isLoading}
          disabled={mutation.isLoading}
          control={form.control}
          name="dailySumAmount"
          onBlur={form.handleSubmit(({ dailySumAmount }) => handleChangeSingleAmountLimit({ dailySumAmount }))}
          labelText="24hr sum amount"
        />
      </Box>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '32px', width: '100%' }}>
        {usageLimits.map((limit) =>
          limit.values ? (
            <Box key={limit.code} sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Typography variant="body1" fontWeight="bold">
                {limit.code}
              </Typography>
              <DataGridPro
                rowHeight={35}
                rows={limit.values}
                disableColumnMenu
                disableColumnResize
                hideFooter
                getRowId={(row) => row.code as string}
                columns={[
                  {
                    headerName: 'Code',
                    field: 'code',
                    flex: 1,
                    sortable: false
                  },
                  {
                    headerName: 'Count',
                    field: 'count',
                    flex: 1,
                    sortable: false
                  },
                  {
                    headerName: 'Single amount',
                    field: 'singleAmount',
                    flex: 1,
                    sortable: false,
                    renderCell: ({ value }) => {
                      if (!value) return '-'

                      return formatCurrency({ value, currency: 'SEK' })
                    }
                  },
                  {
                    headerName: 'Sum amount',
                    field: 'sumAmount',
                    flex: 1,
                    sortable: false,
                    renderCell: ({ value }) => {
                      if (!value) return '-'

                      return formatCurrency({ value, currency: 'SEK' })
                    }
                  }
                ]}
              />
            </Box>
          ) : (
            showPlaceholders && (
              <Typography variant="body1" fontWeight="bold">
                {limit.code}: no data
              </Typography>
            )
          )
        )}
      </Box>
    </>
  )
}

export default CustomerCardTabOverviewAccountUsageLimits
