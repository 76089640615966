import dayjs from 'dayjs'
import { MouseEventHandler } from 'react'
import pdfInvoice from '../figma/images/pdfInvoice'
import Spacings from '../figma/tokens/Spacings'
import useLanguage from '../flamingo/hooks/useLanguage'
import { Circle, Tag } from '../helpers/AccountStatusHelper'
import TextKeys from '../libs/TextKeys'
import { YYYY_MM_DD } from '../mynt-components/Constants'
import FigmaBox from '../mynt-components/components/FigmaBox'
import FigmaButton from '../mynt-components/components/FigmaButton'
import { DarkTooltip, LightTooltip } from '../mynt-components/components/MyntTooltip'
import SVGContainer from '../mynt-components/components/SVGContainer'
import { AntiloopTextType } from '../tiger/interfaces/Antiloop'
import { getText } from '../tiger/libs/TextRepository'
import TextContainer from './TextContainer'

type TooltipAccountStatusGenericCircle = {
  textKey: AntiloopTextType
  color: string
}

export const _TooltipAccountStatusGenericCircle = ({ color, textKey }: TooltipAccountStatusGenericCircle) => (
  <LightTooltip fullWidth title={<TextContainer textKey={textKey} />} placement="top" content={<Circle color={color} />} />
)

type TooltipAccountStatusCompanyCredit = {
  tagColor: string
  statusTextkey: AntiloopTextType
  color: string
}

export const _TooltipAccountStatusCompanyCredit = ({ tagColor, statusTextkey, color }: TooltipAccountStatusCompanyCredit) => (
  <LightTooltip
    fullWidth
    title={
      <FigmaBox justify="center" align="center" gap={Spacings.min}>
        <TextContainer textKey={TextKeys.CompanyCreditToolTip} />
        <FigmaBox justify="center" align="center">
          <Tag color={tagColor} spacing={Spacings.smallest} fullPadding left={Spacings.min} right={Spacings.min}>
            <TextContainer textKey={statusTextkey} />
          </Tag>
        </FigmaBox>
      </FigmaBox>
    }
    placement="top"
    content={<Circle color={color} />}
  />
)

type TooltipCardPETabGenerateAndSend = {
  onCreatePEReport: () => void
}

export const _TooltipCardPETabGenerateAndSend = ({ onCreatePEReport }: TooltipCardPETabGenerateAndSend) => (
  <DarkTooltip
    fullWidth
    title={<TextContainer textKey={TextKeys.peReconcilliationTooltipText} />}
    placement="top"
    content={<FigmaButton onClick={onCreatePEReport} textKey={TextKeys.peReconGenerateAndSend} />}
  />
)

type TooltipInvoiceInformationPDF = {
  invoiceDate: string
  onClickPdf: ((event?: any) => void) & MouseEventHandler<HTMLDivElement>
}

export const _TooltipInvoiceInformationPDF = ({ invoiceDate, onClickPdf }: TooltipInvoiceInformationPDF) => (
  <DarkTooltip
    fullWidth
    title={<TextContainer textKey={TextKeys.peReconGenerateAndSend} text={dayjs(invoiceDate).format(YYYY_MM_DD)} />}
    placement="top"
    content={
      <FigmaBox onClick={onClickPdf} link>
        <SVGContainer SVG={pdfInvoice} />
      </FigmaBox>
    }
  />
)

type TooltipKYCBeneficialOwnersControlTypes = {
  code: string
  description: string
}

export const _TooltipKYCBeneficialOwnersControlTypes = ({ code, description }: TooltipKYCBeneficialOwnersControlTypes) => (
  <DarkTooltip
    fullWidth
    title={<TextContainer textKey={TextKeys.peReconGenerateAndSend} text={description} />}
    placement="top"
    content={<TextContainer textKey={TextKeys.companyGuarantorRegNumLabel} text={code} />}
  />
)

type TooltipChangeAccountStatusProps = {
  content: JSX.Element
}
export const _TooltipChangeAccountStatus = ({ content }: TooltipChangeAccountStatusProps) => (
  <LightTooltip title={<TextContainer textKey={TextKeys.orderNewCardButton} text="Change status" />} placement="top" content={content} />
)
type TooltipResetAllOverrides = {
  content: JSX.Element
}
export const _TooltipResetAllOverrides = ({ content }: TooltipResetAllOverrides) => (
  <LightTooltip title={<TextContainer textKey={TextKeys.billingSettingsHoverResetAllSettings} />} placement="top" content={content} />
)
type TooltipValueOverridden = {
  defaultValue: string
  content: JSX.Element
}
export const _TooltipValueOverridden = ({ defaultValue, content }: TooltipValueOverridden) => (
  <LightTooltip
    title={
      <TextContainer
        textKey={TextKeys.billingSettingsHoverInfoValueEdited}
        text={TextKeys.billingSettingsHoverInfoValueEdited.characters.replace('XXX', defaultValue)}
      />
    }
    aria-label={`value-overridden-${defaultValue}`}
    placement="top"
    content={content}
  />
)
type TooltipResetOverride = {
  content: JSX.Element
}
export const _TooltipResetOverride = ({ content }: TooltipResetOverride) => (
  <LightTooltip title={<TextContainer textKey={TextKeys.billingSettingsHoverResetSettings} />} placement="top" content={content} />
)

type TooltipCustomerInvoicesProps = {
  content: JSX.Element
}
export const _TooltipCustomerInvoices = ({ content }: TooltipCustomerInvoicesProps) => {
  const language = useLanguage()

  return (
    <LightTooltip
      title={
        <TextContainer textKey={TextKeys.registerExternalPaymentHover} text={getText(TextKeys.registerExternalPaymentHover, language)} />
      }
      placement="top"
      content={content}
    />
  )
}

export const _TooltipCustomerSubscriptionFee = ({ content }: TooltipCustomerInvoicesProps) => (
  <LightTooltip title={<TextContainer textKey={TextKeys.createInvoiceHoverLabel} />} placement="top" content={content} />
)

type TooltipRefreshAddressProps = {
  content: JSX.Element
}
export const _TooltipRefreshAddress = ({ content }: TooltipRefreshAddressProps) => (
  <LightTooltip title={<TextContainer textKey={TextKeys.tooltipTextRefreshAddress} />} placement="top" content={content} />
)
