import styled from '@emotion/styled'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Spacings from 'figma/tokens/Spacings.ts'
import React, { useMemo } from 'react'
import { BackOfficeAccountGetDto, EnfuceAccountType } from '../../api/swagger/definitions/backoffice'
import ColorsDeprecated from '../../figma/tokens/ColorsDeprecated.ts'
import { hasPlatinumAccount } from '../../helpers/CustomerCardAccountHelper.ts'
import { CUSTOMER_ACCOUNT_TYPE_SELECTOR_WIDTH } from '../../mynt-components/WeakHardCodedSizes.ts'

interface CustomerCardTabAccountTypeProps {
  currentCardAccount: BackOfficeAccountGetDto
  cardAccounts: BackOfficeAccountGetDto[]
  setCurrentCardAccount: React.Dispatch<React.SetStateAction<BackOfficeAccountGetDto | undefined>>
}

const CustomerCardTabAccountType: React.FC<CustomerCardTabAccountTypeProps> = ({
  currentCardAccount,
  cardAccounts,
  setCurrentCardAccount
}) => {
  const prepaidAccount = useMemo(() => cardAccounts.find((account) => account.type === EnfuceAccountType.PREPAID), [cardAccounts])
  const platinumAccount = useMemo(() => cardAccounts.find((account) => account.type === EnfuceAccountType.PLATINUM), [cardAccounts])

  const handleAccountSwitch = (accountType) => {
    if (accountType === EnfuceAccountType.PREPAID && prepaidAccount) setCurrentCardAccount(prepaidAccount)
    if (accountType === EnfuceAccountType.PLATINUM && platinumAccount) setCurrentCardAccount(platinumAccount)
  }

  return (
    <StyledSelectInput
      id="accountType"
      labelId="accountTypeLabel"
      label={'Account type'}
      value={hasPlatinumAccount(cardAccounts) ? currentCardAccount.type : 'Your account will be shown here'}
      onChange={(e) => handleAccountSwitch(e.target.value)}
      disabled={!hasPlatinumAccount(cardAccounts)}
    >
      {!hasPlatinumAccount(cardAccounts) && (
        <Item selected value={'Your account will be shown here'}>
          Your account will be shown here
        </Item>
      )}
      {hasPlatinumAccount(cardAccounts) && (
        <Item key={EnfuceAccountType.PLATINUM} value={EnfuceAccountType.PLATINUM}>
          {EnfuceAccountType.PLATINUM}
        </Item>
      )}
      {hasPlatinumAccount(cardAccounts) && (
        <Item key={EnfuceAccountType.PREPAID} value={EnfuceAccountType.PREPAID}>
          {EnfuceAccountType.PREPAID}
        </Item>
      )}
    </StyledSelectInput>
  )
}

const StyledSelectInput = styled(Select)`
  width: ${CUSTOMER_ACCOUNT_TYPE_SELECTOR_WIDTH};

  legend {
    visibility: hidden;
    max-width: 0.01px;
  }
`

const Item = styled(MenuItem)`
  && {
    cursor: pointer;
    color: ${ColorsDeprecated.baseGrey};
    padding-left: ${Spacings.small};
    padding-right: ${Spacings.small};

    &:hover {
      color: ${ColorsDeprecated.baseBlack};
      background-color: ${ColorsDeprecated.baseStone};
    }
  }
`

export default CustomerCardTabAccountType
