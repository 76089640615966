import { BillingSettingsFeature, BillingSettingsFeatureName, BillingSettingsTrial } from 'api/swagger/definitions/backoffice'
import { MutableRefObject, useEffect, useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import type { BillingSettingsFormData } from 'pages/CustomerCardTab/BillingSettings/BillingSettingsForm'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { ModalAreYouSureWithBody } from 'components/Modals'
import { DatePickerControllerV2, TextFieldController } from 'components/react-hook-components'
import Colors from 'figma/panda/Colors'
import { prettifyEnumLabel } from 'helpers/CreditOnCardHelpers'
import TextKeys from 'libs/TextKeys'
import MaterialSwitchExperimental from 'mynt-components/components/MaterialSwitchExperimental'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { OverrideSettingComponent } from './OverrideSettingComponent'
import { useResetFeatureOverride, useUpdateFeatureOverrides, useUpdateFeatureTrial } from 'api/react-query'
import { SettingsContainer } from 'pages/CustomerCardTab/BillingSettings/SettingsContainer'
import Divider from '@mui/material/Divider'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js'
import * as Yup from 'yup'

const FeatureSettings = ({
  anchor,
  customerId,
  isLoading
}: {
  anchor: MutableRefObject<HTMLDivElement | null>
  customerId: string
  isLoading: boolean
}) => {
  const { watch } = useFormContext<BillingSettingsFormData>()

  const { mutate: mutateFeatureOverrides, isLoading: isUpdating } = useUpdateFeatureOverrides(customerId)
  const { mutateAsync: resetOverrides, isLoading: isResetingOverrides } = useResetFeatureOverride(customerId)

  const features = watch('features')
  const trials = watch('trials')
  const overriddenFeatures: BillingSettingsFeature[] = features // .filter((f) => f.price.isOverride || f.isEnabled.isOverride)

  const [featureToToggle, setFeatureToToggle] = useState<
    { name: BillingSettingsFeatureName; isBeta: boolean; isEnabled: boolean } | undefined
  >(undefined)

  const overridenFeaturesByName = overriddenFeatures.reduce(
    (acc, feature) => ({
      ...acc,
      [feature.featureName]: feature
    }),
    {} as PriceFormValues
  )

  const handleOnToggle = (featureName: BillingSettingsFeatureName, isEnabled: boolean, isBeta: boolean) => {
    if (isBeta) {
      setConfirmToggleBetaFeature(true)
      setFeatureToToggle({ name: featureName, isEnabled: isEnabled, isBeta: isBeta })
      return
    }
    mutateFeatureOverrides({ featureName, enabled: isEnabled })
  }

  const priceForm = useForm<PriceFormValues>({
    defaultValues: overridenFeaturesByName,
    resolver: yupResolver(featureSchema)
  })

  const loading = isLoading || isUpdating || isResetingOverrides

  useEffect(() => {
    priceForm.reset(overridenFeaturesByName)
  }, [features])

  const handleOnPriceBlur = (feature: BillingSettingsFeatureName) => (values: PriceFormValues) => {
    const price = Number(values[feature].price?.value.value)

    if (isNaN(price)) {
      return
    }

    mutateFeatureOverrides({ featureName: feature, price })
  }

  const [confirmToggleBetaFeature, setConfirmToggleBetaFeature] = useState(false)
  const handleToggleBetaFeature = (name: BillingSettingsFeatureName, isEnabled: boolean) => {
    mutateFeatureOverrides({ featureName: name, enabled: isEnabled })
  }

  async function handleResetSpecificOverride(name: BillingSettingsFeatureName) {
    await resetOverrides(name)
  }

  return (
    <Box flex={1}>
      {confirmToggleBetaFeature && featureToToggle?.isBeta && (
        <ModalAreYouSureWithBody
          bodyText={TextKeys.billingSettingsModalTextBeta}
          onClose={() => setConfirmToggleBetaFeature(false)}
          onContinue={() => {
            setConfirmToggleBetaFeature(false)
            if (featureToToggle) {
              handleToggleBetaFeature(featureToToggle.name, featureToToggle.isEnabled)
            }
          }}
          anchor={anchor.current}
        />
      )}
      <Box sx={{ display: 'flex', flexGrow: '1', width: '100%' }}>
        <SettingsContainer title="Features">
          {overriddenFeatures
            ?.toSorted((a, b) => mapFeaturesNameToReadable(a.featureName).localeCompare(mapFeaturesNameToReadable(b.featureName)))
            ?.map((feature) => (
              <Box
                key={feature.featureName}
                sx={{
                  width: '100%',
                  gap: 3,
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', flex: 1 }}>
                  <Typography sx={{ color: Colors.baseBlack }} variant="body2" style={{ minWidth: '200px' }}>
                    {mapFeaturesNameToReadable(feature.featureName)}
                  </Typography>
                  <MaterialSwitchExperimental
                    disabled={loading}
                    data-testid={`switch-${feature.featureName}`}
                    onChange={(e) => handleOnToggle(feature.featureName, e.target.checked, feature.isBeta)}
                    name={feature.featureName}
                    checked={feature.isEnabled.value}
                    value={feature.isEnabled.value}
                  />
                </Box>
                <Box data-testid="price-input-container">
                  <TextFieldController
                    control={priceForm.control}
                    loading={loading}
                    name={`${feature.featureName}.price.value.value`}
                    adornment={<span>{feature.price.value.currency}</span>}
                    labelText="Price"
                    onBlur={priceForm.handleSubmit(handleOnPriceBlur(feature.featureName))}
                  />
                </Box>
                <Box width={50}>
                  {(feature.isEnabled.isOverride || feature.price.isOverride) && (
                    <OverrideSettingComponent
                      defaultValue={`${feature.isEnabled.originalValue ?? false} ${
                        feature.price.isOverride ? `and ${feature.price.originalValue?.value}` : ''
                      }`}
                      onClickResetOverride={() => handleResetSpecificOverride(feature.featureName)}
                    />
                  )}
                </Box>
              </Box>
            ))}
          <FeatureTrials customerId={customerId} trials={trials} />
        </SettingsContainer>
      </Box>
    </Box>
  )
}

const featureSchema = Yup.object(
  Object.fromEntries(
    Object.keys(BillingSettingsFeatureName).map((key) => [
      key,
      Yup.object({
        price: Yup.object().shape({
          value: Yup.object().shape({
            value: Yup.number().required().min(0, 'Must be at least 0').typeError('Please enter a valid number'),
            currency: Yup.string()
          })
        })
      })
    ])
  )
)

const FeatureTrials = ({ customerId, trials }: { customerId: string; trials: BillingSettingsTrial[] }) => {
  const { mutate: updateTrial } = useUpdateFeatureTrial(customerId)
  const form = useForm<TrialFormValues>({
    defaultValues: createTrialsByName(trials)
  })

  useEffect(() => {
    form.reset(createTrialsByName(trials), { keepDirtyValues: false, keepDefaultValues: false })
  }, [trials])

  const handleUpdateTrial = (trial: BillingSettingsFeatureName) => (values: TrialFormValues) => {
    const initialLength = Number(trials.find((t) => t.featureName === trial)?.lengthInDays)
    if (isNaN(initialLength) || initialLength === values[trial].lengthInDays) {
      return
    }

    updateTrial({ featureName: trial, lengthInDays: values[trial].lengthInDays })
  }

  if (trials.length < 1) {
    return <></>
  }

  return (
    <>
      <Divider />
      <Box sx={{ width: '100%' }}>
        <Typography sx={{ color: Colors.baseBlack }} fontSize={'16px'} fontWeight={700} variant="body1">
          Trials
        </Typography>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', gap: '24px', flexDirection: 'column' }}>
        {trials
          .toSorted((a, b) => mapFeaturesNameToReadable(a.featureName).localeCompare(mapFeaturesNameToReadable(b.featureName)))
          .map((trial) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                gap: '16px'
              }}
              key={trial.featureName}
            >
              <Typography sx={{ color: Colors.baseBlack }} variant="body2" style={{ minWidth: '200px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {mapFeaturesNameToReadable(trial.featureName)}
                  {trial.startDate && (
                    <Typography color={Colors.base500} variant="subtitle2">
                      {getTriallDurationLeft(trial.startDate, trial.lengthInDays)}
                    </Typography>
                  )}
                </Box>
              </Typography>
              <Box data-testid="trail-date-container" sx={{ maxWidth: '400px', display: 'flex', gap: '16px', flexDirection: 'row' }}>
                <Box sx={{ minWidth: '180px' }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePickerControllerV2
                      readOnly
                      control={form.control}
                      name={`${trial.featureName}.startDate`}
                      labelText="Start date"
                    />
                    {}
                  </LocalizationProvider>
                </Box>
                <Box style={{ minWidth: '120px' }}>
                  <TextFieldController
                    control={form.control}
                    name={`${trial.featureName}.lengthInDays`}
                    labelText="Duration"
                    adornment={<span>Days</span>}
                    onBlur={form.handleSubmit(handleUpdateTrial(trial.featureName))}
                  />
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </>
  )
}

const createTrialsByName = (trials: BillingSettingsTrial[]): TrialFormValues =>
  trials.reduce(
    (acc, trial) => ({
      ...acc,
      [trial.featureName]: trial
    }),
    {} as TrialFormValues
  )

const getTriallDurationLeft = (startDate: string, duration: number) => {
  const length = dayjs(startDate)
    .add(duration + 1, 'days') // Add 1 day to include the current day
    .diff(dayjs(), 'days')

  return length < 0 ? `Expired ${Math.abs(length)} days ago` : `${length} days left`
}

const mapFeaturesNameToReadable = (featureName: BillingSettingsFeatureName) => {
  switch (featureName) {
    case 'AUTO_RECEIPT_TRANSACTION_MATCHING':
      return 'Auto Receipt Matching'
    case 'ERP_INTEGRATION_BUSINESS_CENTRAL':
      return 'Business Central'
    case 'ERP_INTEGRATION_VISMA_NET':
      return 'Visma Net'
    default:
      return prettifyEnumLabel(featureName)
  }
}
type PriceFormValues = Record<BillingSettingsFeatureName, BillingSettingsFeature>
type TrialFormValues = Record<BillingSettingsFeatureName, BillingSettingsTrial>

export default FeatureSettings
