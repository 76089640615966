import React, { useEffect, useState } from 'react'

import { RegionAndEcommBlockingInterface } from '../../interfaces/CardInterfaces.ts'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

interface CustomerCardTabOverviewSingleCardDetailsRegionAndEcommBlockingProps {
  data: RegionAndEcommBlockingInterface
  onCheckboxClick: (property: string) => Promise<void>
  isCardLoading?: boolean
}

const CustomerCardTabOverviewSingleCardDetailsRegionAndEcommBlocking: React.FC<
  CustomerCardTabOverviewSingleCardDetailsRegionAndEcommBlockingProps
> = ({ data, onCheckboxClick, isCardLoading }) => {
  const [submitting, setSubmitting] = useState(false)

  const properties = [
    { title: 'Online payments', property: 'ecomm' },
    { title: 'Africa', property: 'africa' },
    { title: 'Asia', property: 'asia' },
    { title: 'Europe', property: 'europe' },
    { title: 'Home', property: 'home' },
    { title: 'North america', property: 'northAmerica' },
    { title: 'South america', property: 'southAmerica' },
    { title: 'Oceania', property: 'oceania' }
  ]

  const handleOnCheckboxClick = async (property: string) => {
    setSubmitting(true)
    await onCheckboxClick(property)
  }

  useEffect(() => {
    setSubmitting(false)
  }, [data])

  if (isCardLoading) {
    return (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '24px' }}>
        <CircularProgress />
      </Box>
    )
  }

  if (!data) {
    return (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '24px' }}>
        <Typography variant="body1">Card does not have regionAndEcommBlocking</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', maxHeight: '150px', padding: '16px 0' }}>
      {properties.map((prop) => (
        <FormControlLabel
          onChange={() => handleOnCheckboxClick(prop.property)}
          key={prop.title}
          checked={data[prop.property]}
          control={<Checkbox disabled={submitting} defaultChecked />}
          label={prop.title}
        />
      ))}
    </Box>
  )
}

// <Checkbox
// className="small"
// style={{ marginRight: 10, position: 'relative', zIndex: 10 }}
// disabled={submitting}
// checked={data[prop.property as keyof RegionAndEcommBlockingInterface]}
// onClick={async () => handleOnCheckboxClick(prop.property)}
// >
// {prop.title}
// </Checkbox>

export default CustomerCardTabOverviewSingleCardDetailsRegionAndEcommBlocking
