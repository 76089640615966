import { ReactNode } from 'react'
import { AntiloopTextType } from 'tiger/interfaces/Antiloop'
import { CREDIT_REQUEST_WIDTH } from 'mynt-components/StrongHardCodedSizes'
import Spacings from 'figma/tokens/Spacings'
import TextContainer from 'components/TextContainer'
import TextKeys from 'libs/TextKeys'
import Box from '@mui/material/Box'
import ColorsDeprecated from 'figma/tokens/ColorsDeprecated'
import BorderRadius from 'figma/tokens/BorderRadius'

export const SettingsContainer = ({
  children,
  title,
  bgColor,
  action
}: {
  children: ReactNode
  title: AntiloopTextType | string
  bgColor?: string
  action?: ReactNode
}) => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      padding: Spacings.medium,
      border: `1px solid ${ColorsDeprecated.baseStone}`,
      borderRadius: BorderRadius.soft,
      margin: Spacings.tiny,
      marginLeft: 0,
      minWidth: `${CREDIT_REQUEST_WIDTH}px`,
      flexDirection: 'column',
      gap: Spacings.medium,
      backgroundColor: bgColor
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      {typeof title === 'object' ? (
        <TextContainer textKey={title} />
      ) : (
        <TextContainer text={title} textKey={TextKeys.billingSettingsRecurringFeesHeading} />
      )}
      {action}
    </Box>
    <>{children}</>
  </Box>
)
