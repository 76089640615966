import React, { useEffect, useState } from 'react'
import { AccountStatusPatch, BackOfficeAccountGetDto } from '../../api/swagger/definitions/backoffice'
import { useUpdateCustomerCardAccount } from '../../api/react-query'
import { CoreMyntModal } from '../../components/modals/CoreMyntModal.tsx'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { SelectController } from '../../components/react-hook-components.tsx'
import { useForm } from 'react-hook-form'
import { EnumToOptions } from 'helpers/CreditOnCardHelpers.tsx'
import LoadingButton from '@mui/lab/LoadingButton'

interface CustomerCardTabChangeStatusModalProps {
  isOpen: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  cardAccount: BackOfficeAccountGetDto
  customerId: string
  onSuccess?: () => void
}

const CustomerCardTabChangeStatusModal: React.FC<CustomerCardTabChangeStatusModalProps> = ({
  isOpen,
  setIsModalOpen,
  onSuccess,
  cardAccount,
  customerId
}) => {
  const cardAccountMutation = useUpdateCustomerCardAccount(customerId)
  const [submitting, setSubmitting] = useState(false)

  const form = useForm({
    defaultValues: {
      accountStatus: cardAccount?.status as AccountStatusPatch
    }
  })

  useEffect(() => {
    if (!cardAccount) return

    form.reset({ accountStatus: cardAccount.status as AccountStatusPatch })
  }, [cardAccount])

  const setAccountStatus = async (status: AccountStatusPatch) => {
    setSubmitting(true)
    await cardAccountMutation.mutateAsync({ status, id: cardAccount.id })

    setTimeout(() => {
      setSubmitting(false)
      setIsModalOpen(false)
      onSuccess && onSuccess()
    }, 300)
  }

  return (
    <CoreMyntModal
      onClose={() => setIsModalOpen(false)}
      open={isOpen}
      title="Change account status"
      actions={(Wrapper) => (
        <Wrapper>
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <LoadingButton
            onClick={form.handleSubmit(({ accountStatus }) => setAccountStatus(accountStatus))}
            disabled={submitting}
            loading={submitting}
            variant="contained"
          >
            Save
          </LoadingButton>
        </Wrapper>
      )}
    >
      <Box sx={{ paddingTop: '16px' }}>
        <SelectController
          control={form.control}
          name="accountStatus"
          labelText="Account status"
          options={EnumToOptions(AccountStatusPatch)}
        />
      </Box>
    </CoreMyntModal>
  )
}

export default CustomerCardTabChangeStatusModal
