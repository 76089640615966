/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import {
  AccountDto,
  AccountXYear,
  ActivationGuidePatchDto,
  BackOfficeAccountPatchDto,
  BackOfficeAgreementTypeV6,
  BackOfficeCardCreditPatchV2Dto,
  BackOfficeCreatePaymentForCustomerRequestDto,
  BackOfficeCustomerCreateDtoV2,
  BackOfficeCustomerDtoV2,
  BackOfficeInvoicePatchDto2,
  BackofficeInvoicingConfigPatchDto,
  BackOfficeOrderCardRequestDto,
  BackOfficePayoutPatchDtoV2,
  BillingSettingsFeatureName,
  BillingSettingsFeatureOverridePatch,
  BillingSettingsFieldName,
  BillingSettingsOverridePatch,
  BillingSettingsTrialOverridePatch,
  BureauConfigRequest,
  BureauRelationshipRequest,
  CardBalanceConfigPatchDto,
  CreateAgreementV6Request,
  CreateDirectDebitMandateV4Request,
  CreatePaymentRequestDtoV2,
  CreatePersonDto,
  EnfucePayoutState,
  FileContext,
  KycRisk,
  PatchPaymentRequestDtoV2,
  PatchStandardResourceBody,
  PaymentState,
  PersonPatchDto,
  ResetRequest
} from '../swagger/definitions/backoffice'
import {
  ACCOUNTING_ACCOUNTS,
  ACCOUNTING_YEARS,
  ACTIVATION_GUIDE,
  ACTIVATION_GUIDE_VISIBILITY,
  ADD_PAYMENT_WHITELIST,
  ALL_AGREEMENTS,
  ALL_CREDITS,
  All_VOUCHERS,
  AUTOGIRO,
  BUREAU,
  BUREAU_CLIENTS,
  BUREAU_LIST,
  CARD_ACCOUNT,
  CARD_ACCOUNTS,
  CARD_CONFIG,
  CARD_CREDITS,
  CARDS,
  CARDS_V2,
  CORPORATE_CARDS,
  CREATE_CUSTOMER,
  CREATE_KYC_PERSON,
  CUSTOMER_FILES,
  CUSTOMER_LOG,
  CUSTOMER_NOTES,
  CUSTOMER_V2,
  GET_ALL_KYC_ROARING,
  GET_AUTO_GIRO_APPROVAL,
  GET_BILLING_SETTINGS,
  GET_DELIVERY_ADDRESS,
  GET_ENFUCE_PAYOUTS,
  GET_KYC_RESPONSES,
  GET_MANDATES_APPROVAL,
  GET_PAYMENT,
  GET_PAYMENT_RECEIVER,
  GET_PAYMENTS,
  GET_PERSONS,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_ACTIVATION_STATUS,
  INVOICE_CONFIG,
  INVOICE_SUMMARY,
  INVOICES,
  KYC_PEOPLE,
  PAYMENT_REQUESTS,
  PAYMENT_WHITELIST,
  PE_REPORTS,
  PENALTY_FEE,
  PLASTICS,
  PRIVATE_CARDS,
  PRIVATE_CARDS_ID_IN_PAYLOAD,
  REFRESH_ADDRESS,
  REPAYMENT_MODELS,
  RISK_CONFIG,
  SEARCH,
  SEND_WELCOME_EMAIL,
  SMS_TEMPLATES,
  VOUCHER_RESULT
} from './keys'
import {
  activatePlastic,
  addPaymentToWhiteList,
  allCreditsUsingGET,
  bureau,
  bureauAssign,
  bureauClients,
  bureauConfigure,
  bureauList,
  bureauReset,
  cancelDirectDebitMandateV4,
  createAccountingAccount,
  createAccountingYear,
  createAgreementV6,
  createCustomer,
  createDirectDebitMandateV4,
  createEnfucePayoutUsingPOST,
  createInvoiceV2,
  createNote,
  createPaymentRequest,
  createPersonUsingPOST,
  createSmsTemplate,
  deleteAccountingAccount,
  deleteAccountingYear,
  deleteCustomerFile,
  deleteNote,
  deletePersonUsingDELETE,
  deleteSmsTemplate,
  editAccountingYear,
  findCustomerUsingGET2,
  getAccountingAccounts,
  getAccountingYears,
  getActivationGuide,
  getActivationVisibility,
  getAllAgreementsV6,
  getAllEnfucePayoutsV2,
  getAllKycRoaring,
  getAllVouchers,
  getCardCreditsUsingGET,
  getCardDeliveryAddress,
  getCards,
  getCardsV2,
  getCustomerCardAccounts,
  getCustomerFiles,
  getCustomerLog,
  getCustomerNotes,
  getDefaultPenaltyFeeV2,
  getDirectDebitMandatesV4,
  getInvoiceConfig,
  getInvoicesV2,
  getKycPeople,
  getKYCResponses,
  getKycRisks,
  getOrCreateCardBalanceConfigUsingGET,
  getPayment,
  getPaymentReceiver,
  getPaymentRequests,
  getPaymentsUsingGET,
  getPaymentWhitelist,
  getPeopleByCustomerId,
  getPEReports,
  getPreferredMandateV4,
  getPrivateCorporateCards,
  getPrivateCustomerCard,
  getPrivateCustomerCardConfig,
  getPrivateCustomerCardPlastics,
  getRefreshAddress,
  getRepaymentInvoiceSummary,
  getRepaymentModels,
  getSmsTemplates,
  getTransactions,
  getVoucherResult,
  orderCardUsingPOST,
  patchActivationVisibility,
  patchCard,
  patchCardBalanceConfigUsingPATCH,
  patchCardConfig,
  patchCardCreditUsingPATCH,
  patchCardV1,
  patchEnfucePayoutV2,
  patchInvoiceConfig,
  patchPaymentRequest,
  patchPaymentUsingPATCH,
  recalculatePaumentRequests,
  registerExternalRepayment,
  reissueCard,
  removePaymentToWhiteList,
  resendDirectDebitMandateV4,
  resetCardPin,
  search,
  sendWelcomeEmail,
  syncCards,
  syncDirectDebitMandateV4,
  syncScoringFile,
  updateAccountingAccount,
  updateCustomer,
  updateCustomerCardAccount,
  updateInvoiceV2,
  updateKycForPerson,
  updateKycPeople,
  updateKycRisks,
  updateNote,
  updatePersonUsingPATCH,
  updateSmsTemplate,
  uploadScoringFile
} from '..'
import { QueryClient, useQuery, UseQueryOptions } from '@tanstack/react-query'
import useMutationWithInvalidation from './hooks/useMutationWithInvalidation'
import { ObjectWithId } from './interfaces/mynt'
import { OPTIONS } from './config'
import { getTransactionsV2ConstEnum } from '../../helpers/ProcessHandler'
import { usePatchMutation, usePatchMutationOppertunistic } from './hooks/usePatchMutation'
import { usePatchListItemMutation, usePatchListItemMutationOpportunistic } from './hooks/usePatchListItemMutation'
import { useCreateListItemMutation, useCreateListItemMutationOppertunistic } from './hooks/useCreateListItemMutation'
import { useDeleteListItemMutation, useDeleteListItemMutationOpportunistic } from './hooks/useDeleteListItemMutation'
import { getBillingSettings, updateBillingSettings } from 'api/endpoints/billingSettings'

export type VoucherQueryParams = {
  from?: string
  to?: string
  years?: string[]
  accounts?: number[]
}

export type SmsBody = {
  title: string
  text: string
  rev: number
}

// The sms template is just an object type, missing its actual types
export type SmsTemplate = SmsBody & {
  createdAt: number
  deleted: boolean
  id: string
  modifiedAt: number
}

const ALL = 'all'

export const InvalidateStarterGuide = (queryClient: QueryClient) => {
  queryClient.invalidateQueries([ACTIVATION_GUIDE])
}

// Verify Payment
export const usePayment = (paymentId: string | undefined, options?: any) =>
  useQuery([GET_PAYMENT, paymentId], () => getPayment(paymentId as string), options)
export const usePaymentReceiver = (paymentId: string | undefined, options?: any) =>
  useQuery([GET_PAYMENT_RECEIVER, paymentId], () => getPaymentReceiver(paymentId as string), options)
export const usePaymentWhitelist = (customerId: string, options?: any) =>
  useQuery([PAYMENT_WHITELIST, customerId], () => getPaymentWhitelist(customerId as string), options)
export const useAddPaymentToWhitelist = () =>
  useMutationWithInvalidation(
    { queryKey: [ADD_PAYMENT_WHITELIST] },
    ({ paymentId }: { paymentId: string }) => addPaymentToWhiteList(paymentId as string),
    [GET_PAYMENTS]
  )
export const useRemovePaymentWhitelist = (customerId: string) =>
  useMutationWithInvalidation(
    { queryKey: [PAYMENT_WHITELIST, customerId] },
    ({ paymentId }: { paymentId: string }) => removePaymentToWhiteList(customerId, paymentId as string),
    [PAYMENT_WHITELIST]
  )

// Customers
export const useCustomer = (customerId: string, options?: any) =>
  useQuery([CUSTOMER_V2, customerId], async () => findCustomerUsingGET2(customerId), {
    ...options,
    enabled: Boolean(customerId),
    ...OPTIONS
  })
export const useCreateCustomer = () =>
  useMutationWithInvalidation({ queryKey: [CREATE_CUSTOMER] }, async (customerDto: BackOfficeCustomerCreateDtoV2) =>
    createCustomer(customerDto)
  )
export const usePatchCustomer = (customerId: string) =>
  usePatchMutationOppertunistic({ queryKey: [CUSTOMER_V2, customerId] }, async (dto: Partial<BackOfficeCustomerDtoV2>) =>
    updateCustomer(customerId, dto)
  )
export const useRefreshAddress = (customerId: string) =>
  useMutationWithInvalidation({ queryKey: [REFRESH_ADDRESS, customerId] }, async () => getRefreshAddress(customerId), [
    CUSTOMER_V2,
    REFRESH_ADDRESS
  ])
export const useSendWelcomeEmail = (customerId: string) =>
  useMutationWithInvalidation({ queryKey: [SEND_WELCOME_EMAIL, customerId] }, async () => sendWelcomeEmail(customerId))
export const useCustomerEvents = (customerId: string, options?: { enabled: boolean }) =>
  useQuery([CUSTOMER_LOG, customerId], async () => getCustomerLog(customerId), options)
export const useCustomerNotes = (customerId: string, options?: { enabled: boolean }) =>
  useQuery([CUSTOMER_NOTES, customerId], async () => getCustomerNotes(customerId), options)
export const useCreateCustomerNote = (customerId: string) =>
  useCreateListItemMutation({ queryKey: [CUSTOMER_NOTES, customerId] }, async (note: string) => createNote(customerId, note))
export const useDeleteCustomerNote = (customerId: string) =>
  useMutationWithInvalidation({ queryKey: [CUSTOMER_NOTES, customerId] }, async (noteId: string) => deleteNote(customerId, noteId), [
    CUSTOMER_NOTES
  ])
export const useUpdateCustomerNote = (customerId: string) =>
  usePatchListItemMutation(
    { queryKey: [CUSTOMER_NOTES, customerId], getId: (item) => item.id as string },
    async ({ id, text, rev }: { id: string; rev: number; text: string }) => updateNote(customerId, id, text, rev),
    [CUSTOMER_NOTES]
  )

// bureau
export const useBureaus = () => useQuery([BUREAU_LIST], () => bureauList())
export const useBureau = (bureauId: string) => useQuery([BUREAU], () => bureau(bureauId))
export const useAssignBureau = () =>
  useCreateListItemMutationOppertunistic(
    { queryKey: [BUREAU_CLIENTS], getId: (item) => item.bureauClientId as string },
    (payload: BureauRelationshipRequest) => bureauAssign(payload)
  )
export const useConfigureBureau = () =>
  usePatchListItemMutationOpportunistic(
    {
      queryKey: [BUREAU_LIST],
      getId: (item) => item.bureauId as string,
      getIdOppertunistic: (item) => item.bureauId as string
    },
    (payload: BureauConfigRequest) => bureauConfigure(payload)
  )
export const useResetBureau = () =>
  useDeleteListItemMutationOpportunistic(
    {
      queryKey: [BUREAU_LIST],
      getId: (item) => item.bureauId as string,
      getIdOppertunistic: (item) => item.bureauId as string
    },
    (payload: ResetRequest) => bureauReset(payload)
  )
export const useBureauClients = (bureauId: string) => useQuery([BUREAU_CLIENTS], () => bureauClients(bureauId))

// Transactions
export const useTransactionsV2 = (
  customerId: string,
  { offset, limit, query, cardIds, personIds, from, to }: Parameters<typeof getTransactionsV2ConstEnum>[1] = {},
  options: any = {}
) =>
  useQuery(
    [GET_TRANSACTIONS, customerId, offset, limit, query, cardIds, personIds, from, to],
    async () => getTransactionsV2ConstEnum(customerId, { offset, limit, query, cardIds, personIds, from, to }),
    { staleTime: Infinity, refetchOnWindowFocus: true }
  )
export const useTransactions = (
  customerId: string,
  { page, offset, limit }: { page?: number; offset?: number; limit?: number } = {},
  options: any = {}
) =>
  useQuery(
    [GET_TRANSACTIONS_ACTIVATION_STATUS, customerId],
    async () =>
      getTransactions(customerId, {
        page,
        offset,
        limit
      }),
    options
  )

// Cards
export const usePatchCardV1 = (customerId: string, cardId: string) =>
  usePatchListItemMutation(
    { queryKey: [CARDS_V2, customerId], getId: (item) => item.id },
    async (
      payload: Partial<{
        singleAmountLimit: number
        dailySumAmount: number
      }>
    ) => patchCardV1(customerId, cardId, payload),
    [PLASTICS, PRIVATE_CARDS]
  )
export const useCards = (customerId: string, options: any = {}) => useQuery([CARDS, customerId], async () => getCards(customerId), options)
export const useCardsV2 = (customerId: string, options: any = {}) =>
  useQuery([CARDS_V2, customerId], async () => getCardsV2(customerId), options)
export const useCardDeliveryAdress = (customerId?: string, personId?: string) =>
  useQuery([GET_DELIVERY_ADDRESS, customerId, personId], async () => getCardDeliveryAddress(customerId as string, personId as string), {
    enabled: !!customerId && !!personId
  })
export const useCardBalanceConfig = (customerId: string) =>
  useQuery([AUTOGIRO, customerId], async () => getOrCreateCardBalanceConfigUsingGET(customerId))
export const useCreateCardBalanceConfig = (customerId: string) =>
  usePatchMutation({ queryKey: [AUTOGIRO, customerId] }, async (req: CardBalanceConfigPatchDto) =>
    patchCardBalanceConfigUsingPATCH(req, customerId)
  )
export const usePrivateCorporateCards = (customerId: string, options: any = {}) =>
  useQuery([CORPORATE_CARDS, customerId], async () => getPrivateCorporateCards(customerId), options)
export const usePrivateCustomerCard = (customerId: string, cardId: string) =>
  useQuery([PRIVATE_CARDS, customerId, cardId], async () => getPrivateCustomerCard(customerId, cardId))
export const usePrivateCustomerCardPlastics = (customerId: string, cardId: string) =>
  useQuery([PLASTICS, customerId], async () => getPrivateCustomerCardPlastics(customerId, cardId))
export const usePrivateCustomerCardConfig = (customerId: string, cardId?: string) =>
  useQuery([CARD_CONFIG, customerId], async () => getPrivateCustomerCardConfig(customerId, cardId as string), { enabled: Boolean(cardId) })
export const usePatchCardConfig = (customerId: string, cardId: string) =>
  usePatchMutation({ queryKey: [CARD_CONFIG, customerId] }, async (config: Record<string, unknown> | any) =>
    patchCardConfig(customerId, cardId, config)
  )
export const useReissueCard = (customerId: string) =>
  useMutationWithInvalidation({ queryKey: [PRIVATE_CARDS, customerId] }, async (cardId: string) => reissueCard(customerId, cardId), [
    PRIVATE_CARDS
  ])
export const useResetCardPin = (customerId: string) =>
  useMutationWithInvalidation({ queryKey: [PRIVATE_CARDS, customerId] }, async (cardId: string) => resetCardPin(customerId, cardId), [
    PRIVATE_CARDS
  ])
export const usePatchCard = (customerId: string, cardId: string) =>
  useMutationWithInvalidation(
    { queryKey: [PRIVATE_CARDS, cardId, customerId] },
    async (req: PatchStandardResourceBody) => patchCard(customerId, cardId, req),
    [PRIVATE_CARDS]
  )
export const usePatchCardIdInPayload = (customerId: string) =>
  useMutationWithInvalidation(
    { queryKey: [PRIVATE_CARDS_ID_IN_PAYLOAD, customerId] },
    async ({ id, ...req }: PatchStandardResourceBody & { id: string }) => patchCard(customerId, id, req)
  )
export const useCreateCardMutation = (customerId: string) =>
  useMutationWithInvalidation(
    { queryKey: [PRIVATE_CARDS, customerId] },
    async (req: BackOfficeOrderCardRequestDto, options?: any) => orderCardUsingPOST(customerId, req, options),
    [PRIVATE_CARDS]
  )
export const usePatchCardCredit = (customerId: string) =>
  usePatchListItemMutationOpportunistic(
    { queryKey: [CARD_CREDITS, customerId], getId: (item) => item.id, getIdOppertunistic: (item) => item.creditId },
    async ({ creditId, ...body }: BackOfficeCardCreditPatchV2Dto & { creditId: string }) =>
      patchCardCreditUsingPATCH(customerId, creditId, body)
  )
export const useCardCredits = (customerId: string, options: any = {}) =>
  useQuery([CARD_CREDITS, customerId], async () => getCardCreditsUsingGET(customerId), { ...OPTIONS, ...options })
export const useActivatePlastic = (customerId: string) =>
  useMutationWithInvalidation(
    { queryKey: [PLASTICS, customerId] },
    async ({ cardId, plasticId }: { cardId: string; plasticId: string }) => activatePlastic(customerId, cardId, plasticId),
    [PLASTICS]
  )
export const useSyncCards = (customerId: string) =>
  useMutationWithInvalidation({ queryKey: [CARDS] }, async (cardId: string) => syncCards(customerId, cardId), [CARDS])

// Card account
export const useUpdateCustomerCardAccount = (customerId: string) =>
  usePatchListItemMutation(
    { queryKey: [CARD_ACCOUNT, customerId], getId: (item) => item.id },
    async ({
      status,
      id
    }: BackOfficeAccountPatchDto & {
      id: string
    }) => updateCustomerCardAccount(customerId, id, { status })
  )
export const useCustomerCardAccounts = (customerId: string, options: any = {}) =>
  useQuery([CARD_ACCOUNTS, customerId], async () => getCustomerCardAccounts(customerId), { ...OPTIONS, ...options })

// Accounting
export const useAccountingYears = (options?: any) =>
  useQuery([ACCOUNTING_YEARS], async () => getAccountingYears() as Promise<AccountXYear[]>, options)
export const useAccountingAccounts = (year?: string, options?: any) =>
  useQuery([ACCOUNTING_ACCOUNTS, year], async () => getAccountingAccounts(year), options)
export const useCreateAccountingYear = () =>
  useCreateListItemMutation({ queryKey: [ACCOUNTING_YEARS] }, async (yearDto: AccountXYear) => createAccountingYear(yearDto))
export const useUpdateAccountingYear = (id: string) =>
  usePatchListItemMutation(
    {
      queryKey: [ACCOUNTING_YEARS, id],
      getId: (item) => item.id as string
    },
    async (yearDto: AccountXYear) => editAccountingYear(id, yearDto)
  )
export const useCreateAccountingAccount = () =>
  useCreateListItemMutation({ queryKey: [ACCOUNTING_ACCOUNTS] }, async (accountDto: AccountDto) => createAccountingAccount(accountDto))
export const useUpdateAccountingAccount = (id: string) =>
  usePatchListItemMutation(
    {
      queryKey: [ACCOUNTING_ACCOUNTS, id],
      getId: (item) => item.id as string
    },
    async (accountDto: AccountDto) => updateAccountingAccount(id, accountDto)
  )
export const useDeleteAccountingAccount = () =>
  useMutationWithInvalidation({ queryKey: [ACCOUNTING_ACCOUNTS] }, async ({ id }: { id: string }) => deleteAccountingAccount(id), [
    ACCOUNTING_ACCOUNTS
  ])
export const useDeleteAccountingYear = () =>
  useMutationWithInvalidation({ queryKey: [ACCOUNTING_YEARS] }, async (id: string) => deleteAccountingYear(id), [ACCOUNTING_YEARS])
export const useVoucherResult = ({ from, to, years, accounts }: VoucherQueryParams = {}, options?: any) =>
  useQuery([VOUCHER_RESULT, from, to, years, accounts], async () => getVoucherResult(from, to, years, accounts), options)
export const useAllVouchers = ({ from, to, years, accounts }: VoucherQueryParams = {}, options?: any) =>
  useQuery([All_VOUCHERS, from, to, years, accounts], async () => getAllVouchers(from, to, years, accounts), options)

// Payment Requests
export const useCreatePaymentRequest = () =>
  useCreateListItemMutation({ queryKey: [PAYMENT_REQUESTS] }, async (req: CreatePaymentRequestDtoV2) => createPaymentRequest(req))
export const usePaymentRequests = (customerId: string | undefined, filters: Parameters<typeof getPaymentRequests>[1], options?: any) =>
  useQuery([PAYMENT_REQUESTS, customerId, filters], async () => getPaymentRequests(customerId, filters, options))
export const usePatchPaymentRequests = () =>
  usePatchListItemMutationOpportunistic(
    { queryKey: [PAYMENT_REQUESTS], getId: (item) => item.id, getIdOppertunistic: (item) => item.id },
    async ({ id, ...req }: PatchPaymentRequestDtoV2 & ObjectWithId) => patchPaymentRequest(id, req)
  )
export const useRecalculatePaymentRequest = () =>
  usePatchListItemMutation(
    {
      queryKey: [PAYMENT_REQUESTS],
      getId: (item) => item.id
    },
    async (id: string) => recalculatePaumentRequests(id)
  )

// SMS
export const useCreateSmsTemplate = () =>
  useCreateListItemMutation({ queryKey: [SMS_TEMPLATES] }, async (body: Partial<SmsBody>) => createSmsTemplate(body))
export const useDeleteSmsTemplate = () =>
  useMutationWithInvalidation({ queryKey: [SMS_TEMPLATES] }, async (id: string) => deleteSmsTemplate(id), [SMS_TEMPLATES])
export const useUpdateSmsTemplate = () =>
  usePatchListItemMutation(
    { queryKey: [SMS_TEMPLATES], getId: (item) => item.id as string },
    async ({ id, body }: { id: string; body: SmsBody }) => updateSmsTemplate(id, body)
  )
export const useSmsTemplates = () => useQuery([SMS_TEMPLATES], async () => getSmsTemplates())

// KYC
export const useKycRisks = (customerId: string) => useQuery([customerId, RISK_CONFIG], async () => getKycRisks(customerId))
export const useUpdateKycRisks = (customerId: string) =>
  usePatchMutationOppertunistic({ queryKey: [customerId, RISK_CONFIG] }, async (riskDto: KycRisk) => updateKycRisks(customerId, riskDto))
export const useCreateKycForPerson = (customerId: string) =>
  useMutationWithInvalidation({ queryKey: [CREATE_KYC_PERSON, customerId] }, async (ssn: string) => updateKycForPerson(customerId, ssn), [
    KYC_PEOPLE
  ])
export const useUpdateKycPeople = () =>
  usePatchMutation({ queryKey: [KYC_PEOPLE] }, async (customerId: string) => updateKycPeople(customerId))
export const useKycPeople = (customerId: string) => useQuery([KYC_PEOPLE, customerId], async () => getKycPeople(customerId))
export const useKYCResponses = (customerId: string) =>
  useQuery([GET_KYC_RESPONSES, customerId], async () => getKYCResponses(customerId), OPTIONS)
export const useAllKycRoaring = (customerId: string) => useQuery([GET_ALL_KYC_ROARING], async () => getAllKycRoaring(customerId))

// Invoices
export const useInvoices = (customerId?: string, filters: Parameters<typeof getInvoicesV2>[1] = {}) =>
  useQuery([INVOICES, JSON.stringify(filters) ?? ALL], async () => getInvoicesV2(customerId, filters), { refetchOnWindowFocus: false })
export const useCreateInvoice = () =>
  useCreateListItemMutation({ queryKey: [INVOICES] }, async (invoice: Parameters<typeof createInvoiceV2>[0]) => createInvoiceV2(invoice))
export const usePatchInvoice = () =>
  usePatchListItemMutation(
    {
      queryKey: [INVOICES],
      getId: (item) => item.id
    },
    async (invoice: BackOfficeInvoicePatchDto2 & ObjectWithId) => updateInvoiceV2(invoice)
  )
export const useInvoiceConfig = (customerId: string) => useQuery([INVOICE_CONFIG, customerId], async () => getInvoiceConfig(customerId))
export const usePatchInvoiceConfig = (customerId: string) =>
  usePatchMutationOppertunistic({ queryKey: [INVOICE_CONFIG, customerId] }, (payload: BackofficeInvoicingConfigPatchDto) =>
    patchInvoiceConfig(customerId, payload)
  )
// Persons
export const usePersons = (customerId: string) =>
  useQuery([GET_PERSONS, customerId], async () => getPeopleByCustomerId(customerId), OPTIONS)
export const useDeletePersons = (customerId: string) =>
  useMutationWithInvalidation(
    { queryKey: [GET_PERSONS, customerId] },
    async (req: { personId: string } & ObjectWithId) => deletePersonUsingDELETE(customerId, req.personId),
    [GET_PERSONS]
  )
export const usePatchPerson = (customerId: string) =>
  usePatchListItemMutationOpportunistic(
    {
      queryKey: [GET_PERSONS, customerId],
      getId: (person) => person.id as string,
      getIdOppertunistic: (payload) => payload.personId
    },
    async ({
      personId,
      ...req
    }: PersonPatchDto & {
      personId: string
    }) => updatePersonUsingPATCH(customerId, personId, req),
    [KYC_PEOPLE]
  )
export const useCreatePerson = (customerId: string) =>
  useCreateListItemMutation({ queryKey: [GET_PERSONS, customerId] }, async (personDto: CreatePersonDto) =>
    createPersonUsingPOST(customerId, personDto)
  )

// Credit
export const useAllCredits = ({ customerId, level }: { customerId?: string; level?: string }, options?: any) =>
  useQuery([ALL_CREDITS, customerId, level], async () =>
    allCreditsUsingGET(
      {
        customerId: customerId || undefined,
        level
      },
      options
    )
  )

// Customer scoring files
export const useSyncScoringFile = (customerId: string) =>
  useMutationWithInvalidation(
    { queryKey: [CUSTOMER_FILES, customerId] },
    async ({ id, context }: { id: string; context: FileContext }) => syncScoringFile(customerId, id, context),
    [CUSTOMER_FILES]
  )
export const useUploadScoringFile = () =>
  useMutationWithInvalidation({ queryKey: [CUSTOMER_FILES] }, async ({ url, file }: { url: string; file: File }) =>
    uploadScoringFile(url, file)
  )
export const useDeleteCustomerFile = (customerId: string) =>
  useDeleteListItemMutation(
    {
      queryKey: [CUSTOMER_FILES, customerId],
      getId: (item) => item.id as string
    },
    async (fileId: string) => deleteCustomerFile(customerId, fileId)
  )
export const useCustomerFiles = (customerId: string, contexts: string[]) =>
  useQuery([CUSTOMER_FILES, customerId, contexts], async () => getCustomerFiles(customerId, contexts))

// Activation guide
export const useActivationGuideVisibility = (customerId: string, { enabled }: UseQueryOptions = {}) =>
  useQuery([customerId, ACTIVATION_GUIDE_VISIBILITY], async () => getActivationVisibility(customerId), { enabled })
export const usePatchActivationGuideVisibility = (customerId: string) =>
  usePatchMutationOppertunistic(
    { queryKey: [customerId, ACTIVATION_GUIDE_VISIBILITY] },
    async (activationGuidePatchDto: ActivationGuidePatchDto) => patchActivationVisibility(customerId, activationGuidePatchDto)
  )
export const useActivationGuide = (customerId: string, { enabled }: UseQueryOptions = {}) =>
  useQuery([ACTIVATION_GUIDE, customerId], async () => getActivationGuide(customerId), { enabled })

// Payouts
export const useEnfucePayoutsV2 = (customerId?: string, states?: EnfucePayoutState[]) =>
  useQuery([GET_ENFUCE_PAYOUTS, customerId], async () => getAllEnfucePayoutsV2(customerId, states))
export const useCreateEnfucePayout = (customerId: string) =>
  useCreateListItemMutation({ queryKey: [GET_ENFUCE_PAYOUTS, customerId] }, createEnfucePayoutUsingPOST)
export const useUpdateEnfucePayoutV2 = () =>
  usePatchListItemMutation(
    { queryKey: [GET_ENFUCE_PAYOUTS], getId: (item: any) => item.id },
    (data: { payoutId: string; payout: BackOfficePayoutPatchDtoV2 }) => patchEnfucePayoutV2(data)
  )

// Payments
export const useUpdatePayments = () =>
  usePatchListItemMutation({ queryKey: [GET_PAYMENTS], getId: (item: any) => item.id }, patchPaymentUsingPATCH)
export const usePayments = (state?: PaymentState[]) => useQuery([GET_PAYMENTS, state], async () => getPaymentsUsingGET(state))
export const useRepaymentModels = (options?: any) => useQuery([REPAYMENT_MODELS], async () => getRepaymentModels(), options)
export const useRepaymentInvoiceSummary = (id: string, options?: any) =>
  useQuery([INVOICE_SUMMARY, id], async () => getRepaymentInvoiceSummary(id), options)
export const useCreateExternalPayment = (customerId: string) =>
  useMutationWithInvalidation(
    { queryKey: [INVOICES, ALL, customerId] },
    async (req: BackOfficeCreatePaymentForCustomerRequestDto) => registerExternalRepayment(req),
    [INVOICES]
  )

// Fees
export const useDefaultPenaltyFeeV2 = (customerId: string, options: any = {}) =>
  useQuery([PENALTY_FEE, customerId], async () => getDefaultPenaltyFeeV2(customerId), options)

// Agreements
export const invalidateAgreements = async (queryClient: QueryClient) => queryClient.invalidateQueries([ALL_AGREEMENTS])
export const useAllAgreementsV6 = (customerId: string, agreementType: BackOfficeAgreementTypeV6[], options?: any) =>
  useQuery([ALL_AGREEMENTS, customerId], async () => getAllAgreementsV6(customerId, agreementType), options)
export const useCreateAgreementV6 = (customerId: string) =>
  useCreateListItemMutation({ queryKey: [ALL_AGREEMENTS, customerId] }, async (agreementDto: CreateAgreementV6Request) =>
    createAgreementV6(customerId, agreementDto)
  )

// Mandates
export const useMandateApprovalv4 = (customerId: string, options: any = {}) =>
  useQuery([GET_AUTO_GIRO_APPROVAL, customerId], async () => getPreferredMandateV4(customerId), options)
export const useDirectDebitMandatesV4 = (customerId: string, options: any = {}) =>
  useQuery([GET_MANDATES_APPROVAL, customerId], async () => getDirectDebitMandatesV4(customerId), options)
export const useCreateDirectDebitMandateV4 = (customerId: string) =>
  useMutationWithInvalidation({ queryKey: [GET_MANDATES_APPROVAL, customerId] }, async (req: CreateDirectDebitMandateV4Request) =>
    createDirectDebitMandateV4(customerId, req)
  )
export const useSyncDirectDebitMandateV4 = (customerId: string) =>
  usePatchListItemMutation(
    { queryKey: [GET_MANDATES_APPROVAL, customerId], getId: (item) => item.id as string },
    async (mandateId: string) => syncDirectDebitMandateV4(customerId, mandateId)
  )
export const useCancelDirectDebitMandateV4 = (customerId: string) =>
  usePatchListItemMutation(
    { queryKey: [GET_AUTO_GIRO_APPROVAL, customerId], getId: (item) => item.id as string },
    async (mandateId: string) => cancelDirectDebitMandateV4(customerId, mandateId)
  )
export const useResendDirectDebitMandateV4 = (customerId: string) =>
  usePatchListItemMutation(
    { queryKey: [GET_AUTO_GIRO_APPROVAL, customerId], getId: (item) => item.id as string },
    async (mandateId: string) => resendDirectDebitMandateV4(customerId, mandateId)
  )

// Unsorted
export const useSearch = (query: string, maxRows?: number) =>
  useQuery([SEARCH, query, maxRows], async () => search(query, maxRows), { enabled: !!query })
export const usePEReports = (customerId: string) => useQuery([PE_REPORTS, customerId], async () => getPEReports(customerId), OPTIONS)

// Billing settings
export const useBillingSettings = (customerId: string, options: any = {}) =>
  useQuery({
    queryKey: [GET_BILLING_SETTINGS, customerId],
    queryFn: async () => getBillingSettings(customerId),
    enabled: options.enabled
  })

export const useUpdateBillingSettings = (customerId: string) =>
  useMutationWithInvalidation(
    { queryKey: ['UPDATE_BILLING_SETTINGS'] },
    async (body: BillingSettingsOverridePatch) => updateBillingSettings.updateSettings(customerId, body),
    [GET_BILLING_SETTINGS]
  )

export const useResetBillingSetting = (customerId: string) =>
  useMutationWithInvalidation(
    { queryKey: ['RESET_BILLING_SETTINGS'] },
    async (field: BillingSettingsFieldName) => updateBillingSettings.resetSettings(customerId, field),
    [GET_BILLING_SETTINGS]
  )

export const useUpdateFeatureOverrides = (customerId: string) =>
  useMutationWithInvalidation(
    { queryKey: ['UPDATE_FEATURE_OVERRIDE'] },
    async (body: BillingSettingsFeatureOverridePatch) => updateBillingSettings.patchFeature(customerId, body),
    [GET_BILLING_SETTINGS]
  )

export const useResetFeatureOverride = (customerId: string) =>
  useMutationWithInvalidation(
    { queryKey: ['RESET_FEATURE_OVERRIDE'] },
    async (feature: BillingSettingsFeatureName) => updateBillingSettings.resetFeature(customerId, feature).then((data) => data),
    [GET_BILLING_SETTINGS]
  )

export const useUpdateFeatureTrial = (customerId: string) =>
  useMutationWithInvalidation(
    { queryKey: ['UPDATE_FEATURE_TRIAL'] },
    async (trial: BillingSettingsTrialOverridePatch) => updateBillingSettings.patchTrial(customerId, trial).then((data) => data),
    [GET_BILLING_SETTINGS]
  )
