import '@fontsource/inter'
import '@fontsource/inter/500.css'
import '@fontsource/inter/400.css'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { QueryClient, QueryClientConfig, QueryClientProvider } from '@tanstack/react-query'
import '@total-typescript/ts-reset'
import DefaultMaterialTheme from 'helpers/DefaultMaterialTheme'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import ErrorBoundary from './routes/errorBoundary'
import { GlobalStyles } from './styles/globalStyles'
import dayjs from 'dayjs'
import en from 'dayjs/locale/en'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'

// dayjs.locale({ ...en, weekStart: 1 })
dayjs.extend(utc)
dayjs.extend(tz)

LicenseInfo.setLicenseKey(
  '0144d2617a4a725109372e30eb174306Tz05NzA2NyxFPTE3NTY0NzAwMjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=='
)

const ReactQueryOptions: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false
    }
  }
}

const Root = () => (
  <ThemeProvider theme={DefaultMaterialTheme}>
    <ErrorBoundary>
      <QueryClientProvider client={new QueryClient(ReactQueryOptions)}>
        <GlobalStyles />
        <App />
      </QueryClientProvider>
    </ErrorBoundary>
  </ThemeProvider>
)

ReactDOM.render(<Root />, document.getElementById('root'))
