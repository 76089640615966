import React from 'react'
import { ModalProps } from '../mynt-components/components/ModalExperimental'
import { AntiloopTextType } from '../tiger/interfaces/Antiloop'
import { getText } from '../tiger/libs/TextRepository'
import { CoreMyntModal } from './modals/CoreMyntModal'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'

export type ModalAreYouSureWithBodyProps = ModalProps & {
  onContinue: () => void
  anchor?: any
  bodyText: AntiloopTextType | string | React.ReactNode
  confirmText?: string
  title?: string
  loading?: boolean
}

const getTextElement = (text: AntiloopTextType | string | React.ReactNode) => {
  if (React.isValidElement(text)) {
    return text
  }

  if (typeof text === 'object') {
    return getText(text as AntiloopTextType, 'en')
  }

  return text
}

export const _ModalAreYouSureWithBody: React.FC<ModalAreYouSureWithBodyProps> = ({
  confirmText = 'Continue',
  title = 'Are you sure?',
  loading,
  onContinue,
  ...props
}) => {
  const bodyText = getTextElement(props.bodyText)

  return (
    <CoreMyntModal
      open
      title={title}
      actions={(Wrapper) => (
        <Wrapper>
          <Button variant="outlined" onClick={props.onClose}>
            Cancel
          </Button>
          <LoadingButton loading={loading} variant="contained" onClick={onContinue}>
            {confirmText}
          </LoadingButton>
        </Wrapper>
      )}
      onClose={props.onClose}
    >
      <Box sx={{ maxWidth: '400px' }}>
        <Typography variant="body2" color="#68676A">
          {bodyText}
        </Typography>
      </Box>
    </CoreMyntModal>
  )
}
