import { isEqual } from 'lodash'
import { useFormContext } from 'react-hook-form'
import TextKeys from 'libs/TextKeys'
import TextContainer from 'components/TextContainer'
import { TextFieldController } from 'components/react-hook-components'
import type { BillingSettingsFormData } from './BillingSettingsForm'
import { OverrideSettingComponent } from './OverrideSettingComponent'
import { SettingsContainer } from './SettingsContainer'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { BillingSettingsFieldName } from 'api/swagger/definitions/backoffice'

type SubscriptionFeesProps = {
  isLoading: boolean
  handleReset: (field: BillingSettingsFieldName) => void
  handleUpdate: (value: number) => void
}

export const SubscriptionFees = ({ isLoading, handleReset, handleUpdate }: SubscriptionFeesProps) => {
  const { watch, getFieldState, control, handleSubmit } = useFormContext<BillingSettingsFormData>()
  const defaultFee = watch('fee.amount.originalValue.value')
  const overriddenFee = watch('fee.amount.value.value')
  const isOverridden = watch('fee.amount.isOverride')
  const selectedFee = isOverridden ? overriddenFee : defaultFee
  const currencyCode = watch('fee.amount.value.currency')
  const nextBillingDate = watch('fee.nextBillingDate')

  const onBlurUpdate = () => (data: BillingSettingsFormData) => {
    const isDirty = getFieldState('payload.subscriptionFee').isDirty
    const fee = data.payload.subscriptionFee ?? selectedFee

    if ((isEqual(fee, defaultFee) && !isDirty) || !isDirty || isEqual(fee, selectedFee)) {
      return
    }

    handleUpdate(fee)
  }

  return (
    <SettingsContainer title="Subscription fee">
      <Stack spacing={3}>
        <Box sx={{ display: 'flex', width: '100%', gap: 3, alignItems: 'center' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <TextContainer textKey={TextKeys.billingSettingsRecurringFeesSubscriptionFee} />
            {isOverridden && (
              <OverrideSettingComponent defaultValue={`${defaultFee}`} onClickResetOverride={() => handleReset('SUBSCRIPTION_FEE')} />
            )}
          </Box>
          <TextFieldController
            name="payload.subscriptionFee"
            loading={isLoading}
            disabled={isLoading}
            labelTextKey={TextKeys.billingSettingsRecurringFeesSubscriptionFee}
            control={control}
            type="text"
            onBlur={handleSubmit(onBlurUpdate())}
            adornment={<span>{currencyCode}</span>}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <TextContainer textKey={TextKeys.billingSettingsNextBillingDate} />
          <TextContainer textKey={TextKeys.billingSettingsNextBillingDateValue} text={nextBillingDate ?? '-'} />
        </Box>
      </Stack>
    </SettingsContainer>
  )
}

export default SubscriptionFees
