import * as Sentry from '@sentry/react'
import { BrowserOptions, BrowserTracing } from '@sentry/browser'
import { Extras } from '@sentry/types'
import axios, { AxiosError } from 'axios'
import config from 'config'
import invariant from 'invariant'
import { useEffect } from 'react'
import useRoutingInstrumentation from 'react-router-v6-instrumentation'
import { getAxiosNetworkFingerprint, getAxiosNetworkFingerprintString, getMappedAxiosError } from '../api/axios/helpers'
import { IGNORED_SENTRY_ERRORS } from '../tiger/Constants'

const dsn = process.env.VITE_REACT_APP_SENTRY_DSN
invariant(dsn, 'Cant find env variable REACT_APP_SENTRY_DSN')

export const captureException = (err: Error | AxiosError, extra?: Extras) => {
  if (!config.enableSentry) {
    return
  }

  if (axios.isAxiosError(err)) {
    return captureAxiosError(err, extra)
  }

  Sentry.captureException(err, { extra })
}

export const captureAxiosError = (err: AxiosError, extra?: Extras) => {
  if (!config.enableSentry) {
    return
  }

  Sentry.captureException(getMappedAxiosError(err), (scope) => {
    scope.setFingerprint(getAxiosNetworkFingerprint(err))
    scope.setTag('transaction', getAxiosNetworkFingerprintString(err))
    extra && scope.setExtras(extra)
    return scope
  })
}

export function SentryInit() {
  const routingInstrumentation = useRoutingInstrumentation()

  useEffect(() => {
    const browserTracing = new BrowserTracing({ routingInstrumentation })

    let sentryOptions: BrowserOptions = {
      dsn: dsn,
      integrations: [browserTracing],
      environment: config.name,
      ignoreErrors: IGNORED_SENTRY_ERRORS,
      autoSessionTracking: true,
      enabled: config.enableSentry
    }

    // Sometimes we don't want extended tracing and replays, for example when running the project locally
    if (config.enableSentryTracing) {
      const replay = Sentry.replayIntegration({ maskAllText: true })

      sentryOptions = {
        ...sentryOptions,
        integrations: [browserTracing, replay],
        tracesSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0
      }
    }

    Sentry.init(sentryOptions)
  }, [routingInstrumentation])

  return null
}
