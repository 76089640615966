/* eslint-disable no-console */
import dayjs from 'dayjs'
import { Formik, FormikProps } from 'formik'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ActivationGuideDto,
  ApprovalStatus,
  BackOfficeCustomerDtoV2,
  BackofficeInvoicingConfigDto,
  BillingSettingsPricePlan,
  CardPartner,
  CustomerFeature,
  CustomerProduct,
  SubSystemV2,
  Visibility
} from '../api/swagger/definitions/backoffice'
import config from '../config'
import DeleteCustomerIcon from '../figma/images/deleteCustomerIcon'
import iconButtonEmail from '../figma/images/iconButtonEmail'
import iconButtonrefreshAddress from '../figma/images/iconButtonrefreshAddress'
import iconButtonrefreshAddressHover from '../figma/images/iconButtonrefreshAddressHover'
import Spacings from '../figma/tokens/Spacings'
import {
  useInvoiceConfig,
  usePatchActivationGuideVisibility,
  usePatchInvoiceConfig,
  useRefreshAddress,
  useSendWelcomeEmail
} from '../api/react-query'
import { deleteCustomerUsingDELETE } from '../api'
import {
  BorderedContainer,
  customerHasProduct,
  DeleteCustomerPopper,
  Divider,
  LinkButton,
  objectValueReplace,
  StyledMaterialFieldFormik
} from '../helpers/customerGeneralFormHelper'
import TextKeys from '../libs/TextKeys'
import FigmaBox from '../mynt-components/components/FigmaBox'
import MaterialDatePickerFormik from '../mynt-components/components/MaterialDatePickerFormik'
import MaterialNumberFormatFormik from '../mynt-components/components/MaterialNumberFormatFormik'
import MaterialSelectFieldFormik from '../mynt-components/components/MaterialSelectFieldFormik'
import MaterialSwitchFormik from '../mynt-components/components/MaterialSwitchFormik'
import MyntPopper from '../mynt-components/components/MyntPopper'
import SVGContainer from '../mynt-components/components/SVGContainer'
import { UnstyledButton } from '../mynt-components/components/StyledComponents'
import { CUSTOMERS_PATH } from '../routes/constants'
import { ModalConfirmDialog } from './Modals'
import SendWelcomeEmailPopperContent from './SendWelcomeEmailPopperContent'
import TextContainer from './TextContainer'
import { TooltipRefreshAddress } from './Tooltips'
import { Access, usePermissions } from 'contexts/permissions'
import useMaterialNotification from 'hooks/useMaterialNotification'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { TextFieldController } from './react-hook-components'
import { useForm } from 'react-hook-form'

type CustomerGeneralFormProps = {
  customer: BackOfficeCustomerDtoV2
  activationGuideVisibility?: ActivationGuideDto
  customerMutation: (partialCustomer: Partial<BackOfficeCustomerDtoV2>) => Promise<unknown>
  billingSettingsPricePlan?: BillingSettingsPricePlan
}

// Replace with real enum in future
enum VisibilityEnum {
  COMPLETED = 'COMPLETED',
  HIDDEN = 'HIDDEN',
  'VISIBLE' = 'VISIBLE'
}

/**
 * The following values are sorted in a logical order, best to worst in general i.e feature disabling option is at the bottom.
 *
 * The arrays are reversed since the goal is setting the disabled value at the end only works when its at the start,
 * since sets can only have unique values, spreading all the values,
 * and then adding them again, just removes it the manually placed value and we are left with the original enum.
 *
 * By reversing the arrays, we ensure all the values are unique and are in the right order.
 * Since the value we placed first, are now in the end, resulting in want we want.
 *
 * The reasoning behind this sortof complex strategy is future proofing,
 * if the enums are extended in the future we ensure that they are included, although in the wrong order.
 */

const sortedCardPartnerValues = Array.from(new Set([CardPartner.NONE, ...Object.values(CardPartner)])).reverse()

const sortedAccountingSystemValues = Array.from(new Set([SubSystemV2.NONE, ...Object.values(SubSystemV2)])).reverse()

const sortedApprovalStatus = Array.from(
  new Set([
    ApprovalStatus.AUTO_DENIED,
    ApprovalStatus.MANUALLY_DENIED,
    ApprovalStatus.PENDING,
    ApprovalStatus.AUTO_APPROVED,
    ApprovalStatus.MANUALLY_APPROVED,
    ...Object.values(ApprovalStatus)
  ])
).reverse()

const sortedActivationGuideValue = Array.from(
  new Set([VisibilityEnum.COMPLETED, VisibilityEnum.VISIBLE, VisibilityEnum.HIDDEN, ...Object.values(VisibilityEnum)])
)

const subscriptonPlanValues = Object.values(BillingSettingsPricePlan)

// These came from jakob (https://trello.com/c/7c6Qnt0R/3752-improve-backoffice-and-meaning-of-products-features-etc)
const FIGMA_LEFT_COLUMN_WIDTH = 782

const CustomerGeneralForm: React.FC<CustomerGeneralFormProps> = ({
  customer,
  activationGuideVisibility,
  customerMutation,
  billingSettingsPricePlan
}) => {
  const [canEditToken, setCanEditToken] = useState(false)
  const [showTokenPopper, setShowTokenPopper] = useState(false)
  const [showDeleteCustomerPopper, setShowDeleteCustomerPopper] = useState(false)
  const [showSendWelcomeEmailPopper, setShowSendWelcomeEmailPopper] = useState(false)
  const [hover, setHover] = useState(false)

  const permissions = {
    activationGuide: usePermissions('customers.activation-guide'),
    cardPartner: usePermissions('customers.card-partner'),
    subscription: usePermissions('customers.subscription')
  }

  const notify = useMaterialNotification()

  const tokenElementRef = useRef(null)
  const deleteCustomerElementRef = useRef(null)
  const sendWelcomeEmailElementRef = useRef(null)
  const tokenRef = useRef<HTMLInputElement>(null)

  const activationGuideMutation = usePatchActivationGuideVisibility(customer.id)
  const sendWelcomeEmailMutation = useSendWelcomeEmail(customer.id)

  const { data: invoiceConfig } = useInvoiceConfig(customer.id)
  const invoiceConfigMutation = usePatchInvoiceConfig(customer.id)

  const invoiceConfigForm = useForm<BackofficeInvoicingConfigDto>({
    defaultValues: {
      eInvoiceAddress: invoiceConfig?.eInvoiceAddress || ''
    }
  })

  useEffect(() => {
    invoiceConfigForm.reset({ eInvoiceAddress: invoiceConfig?.eInvoiceAddress })
  }, [invoiceConfig])

  const addressMutation = useRefreshAddress(customer.id)
  const navigate = useNavigate()

  const features = useMemo(() => Array.from(customer.features), [customer])
  const products = useMemo(() => Array.from(customer.products), [customer])

  const initialValues = useMemo(() => {
    const replacer = (value: any) => (value === null ? '' : value)

    const initial = {
      subscription: billingSettingsPricePlan,
      visibility: activationGuideVisibility?.visibility,
      companyName: customer.companyName,
      addressField1: customer.addressField1,
      postCode: customer.postCode,
      orgNo: customer.orgNo,
      addressField2: customer.addressField2,
      postTown: customer.postTown,
      invoiceEmail: customer.invoiceEmail,
      phoneNumber: customer.phoneNumber,
      bankAccount: customer.bankAccount,
      bankgiro: customer.bankgiro,
      subSystem: customer.subSystem,
      channel: customer.channel,
      cardPartner: customer.cardPartner,
      cardPartnerId: customer.cardPartnerId,
      cardPartnerToken: customer.cardPartnerToken,
      cardProduct: products.includes(CustomerProduct.CARD),
      cardTopup: features.includes(CustomerFeature.TOPUP),
      orderCard: features.includes(CustomerFeature.CARD_ORDER),
      cardCredit: products.includes(CustomerProduct.CARD_CREDIT),
      companyCredit: products.includes(CustomerProduct.COMPANY_CREDIT),
      multiInvoiceCredit: products.includes(CustomerProduct.MULTI_INVOICE_CREDIT),
      companyCreditApprovalStatus: customer.companyCreditApprovalStatus,
      companyCreditLimit: customer.companyCreditLimit.value,
      companyCreditReclaimCredit: features.includes(CustomerFeature.RECLAIM_CREDIT),
      companyCreditApprovalExpirationDate: customer.companyCreditApprovalExpirationDate
    }

    return objectValueReplace(initial, replacer) as typeof initial
  }, [customer, features, products, activationGuideVisibility, billingSettingsPricePlan])

  type InitialValuesType = typeof initialValues

  const [loaders, setLoaders] = useState<Partial<Record<keyof InitialValuesType, boolean>>>({})

  const productsMapping: Record<CustomerProduct, keyof InitialValuesType> = {
    [CustomerProduct.CARD]: 'cardProduct',
    [CustomerProduct.CARD_CREDIT]: 'cardCredit',
    [CustomerProduct.COMPANY_CREDIT]: 'companyCredit',
    [CustomerProduct.MULTI_INVOICE_CREDIT]: 'multiInvoiceCredit'
  }

  const featuresMapping: Record<CustomerFeature, keyof InitialValuesType> = {
    [CustomerFeature.TOPUP]: 'cardTopup',
    [CustomerFeature.CARD_ORDER]: 'orderCard',
    [CustomerFeature.RECLAIM_CREDIT]: 'companyCreditReclaimCredit'
  }

  const productArrayValues: (keyof InitialValuesType)[] = ['cardProduct', 'cardCredit', 'companyCredit', 'multiInvoiceCredit']
  const featureArrayValues: (keyof InitialValuesType)[] = ['cardTopup', 'orderCard', 'companyCreditReclaimCredit']

  const fieldToAPIMapping: Partial<Record<keyof InitialValuesType, keyof BackOfficeCustomerDtoV2>> = {
    cardProduct: 'products',
    cardTopup: 'features',
    orderCard: 'features',
    cardCredit: 'products',
    companyCredit: 'products',
    multiInvoiceCredit: 'products',
    companyCreditReclaimCredit: 'features'
  }

  // Add more methods here to format any of the fields if they need special formatting
  const formatValue = (name: keyof InitialValuesType, value: any) => {
    switch (name) {
      case 'companyCreditLimit': {
        return {
          ...customer.companyCreditLimit,
          value: parseInt(value)
        }
      }

      case 'cardProduct': {
        if (customerHasProduct(customer)) return value

        return Array.from(new Set([...value, CustomerProduct.CARD_CREDIT]))
      }

      case 'companyCreditApprovalExpirationDate': {
        return new Date(value)
      }

      default: {
        return value
      }
    }
  }

  /**
   * Sets a loading time for a field
   * @param name name of the field
   * @param promise the mutation promise
   */
  const setLoader = (name: keyof InitialValuesType, promise: Promise<any>) => {
    setLoaders((currentLoaders) => ({ ...currentLoaders, [name]: true }))

    promise.finally(() => {
      setLoaders((currentLoaders) => ({ ...currentLoaders, [name]: false }))
    })
  }

  const handleActivationGuideStatus =
    (name: keyof InitialValuesType, formProps: FormikProps<any>) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      const visibility = event.target.value as Visibility

      activationGuideMutation.mutateAsync({ visibility }).then(() => {
        notify(`Activation guide visibility set to ${visibility}`)
      })
    }

  const handleCustomerDelete = () => {
    setShowDeleteCustomerPopper(false)
    deleteCustomerUsingDELETE(customer.id).then(() => {
      navigate(CUSTOMERS_PATH)
    })
  }

  const handleSendWelcomeEmail = () => {
    sendWelcomeEmailMutation.mutateAsync({ customerId: customer.id }).then(() => setShowSendWelcomeEmailPopper(false))
  }

  const handleEditCompanyToken = () => {
    setCanEditToken(!canEditToken)

    const input = tokenRef.current?.querySelector('input')

    setTimeout(() => input?.focus())
  }

  const handleEditTokenDiscard = (formProps: FormikProps<InitialValuesType>) => {
    setCanEditToken(false)
    setShowTokenPopper(false)

    formProps.setFieldValue('cardPartnerToken', initialValues.cardPartnerToken)
  }

  const handleEditTokenSave = (formProps: FormikProps<InitialValuesType>) => {
    setCanEditToken(false)
    setShowTokenPopper(false)

    const promise = customerMutation({
      cardPartnerToken: formProps.values.cardPartnerToken
    })

    setLoader('cardPartnerToken', promise)

    promise.catch(() => formProps.resetForm())

    // We need to reset the form since the value is masked on the backend
    promise.finally(() => {
      formProps.resetForm()
    })
  }

  const handleInvoicePatchInvoiceConfig = ({ eInvoiceAddress }: BackofficeInvoicingConfigDto) => {
    if (invoiceConfig?.eInvoiceAddress === eInvoiceAddress) return

    invoiceConfigMutation.mutateAsync({ eInvoiceAddress })
  }

  // Any select fields require their own onChange handler, onBlur doesnt work
  const createSelectChangeHandler =
    (name: keyof InitialValuesType, formProps: FormikProps<any>) => (event: React.ChangeEvent<HTMLSelectElement>) => {
      formProps.handleChange(name)(event)

      const newValue = event.target.value
      const oldValue = initialValues[name]

      if (newValue === oldValue) return console.log('no diff')

      // Handle any formatting edgecases, default to the string value
      const formattedValue = formatValue(name, newValue)

      const promise = customerMutation({
        [name]: formattedValue
      })

      setLoader(name, promise)

      promise.catch(() => formProps.resetForm())
    }

  const createBlurHandler = (name: keyof InitialValuesType, formProps: FormikProps<InitialValuesType>) => async (event: any) => {
    // If the value is in an array, send it to the array handler
    if ([...productArrayValues, ...featureArrayValues].includes(name)) {
      return blurHandlerArray(name, formProps, event)
    }

    formProps.validateForm().then((errors) => {
      if (Object.values(errors).length) return

      const newValue = formProps.values[name]
      const oldValue = initialValues[name]

      if (newValue === oldValue) return console.log('no diff')

      // Handle any formatting edgecases, default to the string value
      const formattedValue = formatValue(name, newValue)

      // Empty strings should be null
      const promise = customerMutation({
        [name]: formattedValue
      })

      setLoader(name, promise)

      promise.catch(() => formProps.resetForm())
    })
  }

  const blurHandlerArray = async (name: keyof InitialValuesType, formProps: FormikProps<InitialValuesType>, event: any) => {
    const isProductArray = productArrayValues.includes(name)

    const enumArray = Object.values(isProductArray ? CustomerProduct : CustomerFeature)
    const mapping = isProductArray ? productsMapping : featuresMapping

    const productsArray = enumArray.filter((value) => {
      const key = mapping[value]

      // If the enum value is the currently active array field (the switch)
      // return the checked status as it has not been updated in formik by now
      if (key === name) {
        return event.target.checked
      }

      return formProps.values[key]
    })

    // Handle any formatting edgecases, default to the string value
    const formattedValue = formatValue(name, productsArray)

    const key = fieldToAPIMapping[name]

    if (!key) throw new Error(`'${key}' does not exist in BackOfficeCustomerDtoV2 or '${name}' does not exist in the mapping`)

    // Handle edgecase in spec where if we are enabling card product, we also enabled order card and card credit
    if (name === 'cardProduct' && !customerHasProduct(customer)) {
      const featuresKey = fieldToAPIMapping['orderCard']

      if (!featuresKey)
        throw new Error(`'${featuresKey}' does not exist in BackOfficeCustomerDtoV2 or 'cardCredit' does not exist in the mapping`)

      const features = Array.from(new Set([...Array.from(customer.features)]))

      // Empty strings should be null
      const promise = customerMutation({
        [key]: formattedValue,
        [featuresKey]: features
      })

      setLoader(name, promise)

      return promise.catch(() => formProps.resetForm())
    }

    // Empty strings should be null
    const promise = customerMutation({
      [key]: formattedValue
    })

    setLoader(name, promise)

    promise.catch(() => formProps.resetForm())
  }

  const refreshClick = async () => addressMutation.mutateAsync({ customerId: customer.id })

  const onHover = () => {
    setHover(true)
  }

  const onLeave = () => {
    setHover(false)
  }

  return (
    <FigmaBox spacing={Spacings.max} fullWidth>
      <Formik initialValues={initialValues} onSubmit={(...args) => console.log('submit', ...args)} enableReinitialize>
        {(formProps) => (
          <FigmaBox fullWidth direction="row">
            <FigmaBox style={{ width: '100%' }}>
              <BorderedContainer fullWidth>
                <FigmaBox fullWidth direction="row" align="center" justify="space-between" spacing={Spacings.medium} bottom>
                  <TextContainer textKey={TextKeys.GeneralInfoHeading} />
                  <Access permissions="customers.refresh-address">
                    <TooltipRefreshAddress
                      content={
                        <UnstyledButton onClick={refreshClick} onMouseEnter={onHover} onMouseLeave={onLeave}>
                          {!hover && <SVGContainer SVG={iconButtonrefreshAddress} />}
                          {hover && <SVGContainer SVG={iconButtonrefreshAddressHover} />}
                        </UnstyledButton>
                      }
                    />
                  </Access>
                </FigmaBox>
                <FigmaBox direction="row" fullWidth>
                  <FigmaBox spacing={Spacings.medium} right fullWidth>
                    <StyledMaterialFieldFormik
                      formProps={formProps}
                      fullWidth
                      name="companyName"
                      labelTextKey={TextKeys.companyNameLabel}
                      onBlur={createBlurHandler('companyName', formProps)}
                      loading={loaders['companyName']}
                      disabled={loaders['companyName']}
                    />
                  </FigmaBox>
                  <StyledMaterialFieldFormik
                    formProps={formProps}
                    fullWidth
                    name="addressField1"
                    labelTextKey={TextKeys.addressLabel}
                    onBlur={createBlurHandler('addressField1', formProps)}
                    loading={loaders['addressField1']}
                    disabled={loaders['addressField1']}
                  />
                </FigmaBox>
                <FigmaBox direction="row" fullWidth>
                  <FigmaBox spacing={Spacings.medium} right fullWidth>
                    <StyledMaterialFieldFormik
                      noSpacing
                      formProps={formProps}
                      fullWidth
                      name="postCode"
                      labelTextKey={TextKeys.postalCodeLabel}
                      onBlur={createBlurHandler('postCode', formProps)}
                      loading={loaders['postCode']}
                      disabled={loaders['postCode']}
                      error={formProps.errors.postCode}
                    />
                  </FigmaBox>
                  <StyledMaterialFieldFormik
                    formProps={formProps}
                    fullWidth
                    name="orgNo"
                    labelTextKey={TextKeys.orgNumberLabel}
                    onBlur={createBlurHandler('orgNo', formProps)}
                    loading={loaders['orgNo']}
                    disabled={loaders['orgNo']}
                  />
                </FigmaBox>
                <FigmaBox direction="row" fullWidth>
                  <FigmaBox spacing={Spacings.medium} right fullWidth>
                    <StyledMaterialFieldFormik
                      formProps={formProps}
                      fullWidth
                      name="addressField2"
                      labelTextKey={TextKeys.addressCoLabel}
                      onBlur={createBlurHandler('addressField2', formProps)}
                      loading={loaders['addressField2']}
                      disabled={loaders['addressField2']}
                    />
                  </FigmaBox>
                  <StyledMaterialFieldFormik
                    noSpacing
                    formProps={formProps}
                    fullWidth
                    name="postTown"
                    labelTextKey={TextKeys.cityLabel}
                    onBlur={createBlurHandler('postTown', formProps)}
                    loading={loaders['postTown']}
                    disabled={loaders['postTown']}
                    error={formProps.errors.postTown}
                  />
                </FigmaBox>
                <Divider onlyBottom />
                <Box sx={{ display: 'flex', gap: Spacings.medium, flexDirection: 'column' }}>
                  <FigmaBox direction="row" fullWidth>
                    <FigmaBox spacing={Spacings.tiny} right fullWidth>
                      <StyledMaterialFieldFormik
                        noSpacing
                        formProps={formProps}
                        fullWidth
                        name="invoiceEmail"
                        labelTextKey={TextKeys.invoiceEmailLabel}
                        onBlur={createBlurHandler('invoiceEmail', formProps)}
                        loading={loaders['invoiceEmail']}
                        disabled={loaders['invoiceEmail']}
                      />
                    </FigmaBox>
                    <FigmaBox spacing={Spacings.tiny} left fullWidth>
                      <StyledMaterialFieldFormik
                        noSpacing
                        formProps={formProps}
                        fullWidth
                        name="phoneNumber"
                        labelTextKey={TextKeys.phoneNumberLabel}
                        onBlur={createBlurHandler('phoneNumber', formProps)}
                        loading={loaders['phoneNumber']}
                        disabled={loaders['phoneNumber']}
                        type="tel"
                      />
                    </FigmaBox>
                  </FigmaBox>
                  <FigmaBox direction="row" fullWidth>
                    <FigmaBox spacing={Spacings.tiny} right fullWidth>
                      <TextFieldController
                        control={invoiceConfigForm.control}
                        name="eInvoiceAddress"
                        InputProps={{
                          defaultValue: invoiceConfig?.eInvoiceAddress,
                          onBlur: invoiceConfigForm.handleSubmit(handleInvoicePatchInvoiceConfig),
                          endAdornment: invoiceConfigMutation.isLoading ? (
                            <FigmaBox spacing={Spacings.small} right>
                              <CircularProgress size="20px" />
                            </FigmaBox>
                          ) : null
                        }}
                        labelText="E-Invoice address (Finland)"
                      />
                    </FigmaBox>
                    <FigmaBox spacing={Spacings.tiny} left fullWidth>
                      <a
                        style={{ width: '100%', height: '100%' }}
                        href={`https://verkkolaskuosoite.fi/client/index.html#/?searchText=${customer.companyId?.regNo}&lang=en`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button fullWidth sx={{ height: '100%' }}>
                          Lookup E-invoice address
                        </Button>
                      </a>
                    </FigmaBox>
                  </FigmaBox>
                </Box>
              </BorderedContainer>
              <BorderedContainer fullWidth>
                <FigmaBox spacing={Spacings.medium} bottom>
                  <TextContainer textKey={TextKeys.bankInfoHeading} />
                </FigmaBox>
                <FigmaBox direction="row" fullWidth>
                  <FigmaBox spacing={Spacings.tiny} right fullWidth>
                    <StyledMaterialFieldFormik
                      noSpacing
                      formProps={formProps}
                      fullWidth
                      name="bankAccount"
                      labelTextKey={TextKeys.bankAccountLabel}
                      onBlur={createBlurHandler('bankAccount', formProps)}
                      loading={loaders['bankAccount']}
                      disabled={loaders['bankAccount']}
                    />
                  </FigmaBox>
                  <FigmaBox spacing={Spacings.tiny} left fullWidth>
                    <StyledMaterialFieldFormik
                      noSpacing
                      formProps={formProps}
                      fullWidth
                      name="bankgiro"
                      labelTextKey={TextKeys.bankgiroLabel}
                      onBlur={createBlurHandler('bankgiro', formProps)}
                      loading={loaders['bankgiro']}
                      disabled={loaders['bankgiro']}
                    />
                  </FigmaBox>
                </FigmaBox>
              </BorderedContainer>
              <FigmaBox direction="row" fullWidth>
                <FigmaBox fullWidth spacing={Spacings.tiny} right>
                  <BorderedContainer fullWidth>
                    <FigmaBox spacing={Spacings.medium} bottom fullWidth>
                      <TextContainer textKey={TextKeys.AccountingInfoHeading} />
                    </FigmaBox>
                    <MaterialSelectFieldFormik
                      formProps={formProps}
                      fullWidth
                      name="subSystem"
                      options={sortedAccountingSystemValues.map((value) => ({
                        label: value,
                        value
                      }))}
                      labelTextKey={TextKeys.accountingSystemLabel}
                      onChange={createSelectChangeHandler('subSystem', formProps)}
                      loading={loaders['subSystem']}
                      disabled={loaders['subSystem']}
                    />
                  </BorderedContainer>
                </FigmaBox>
                <FigmaBox fullWidth spacing={Spacings.tiny} left>
                  <BorderedContainer fullWidth style={{ marginRight: 0 }}>
                    <FigmaBox spacing={Spacings.medium} bottom fullWidth>
                      <TextContainer textKey={TextKeys.acquisitionChannelHeading} />
                    </FigmaBox>
                    <StyledMaterialFieldFormik
                      noSpacing
                      formProps={formProps}
                      fullWidth
                      name="channel"
                      labelTextKey={TextKeys.channelLabel}
                      onBlur={createBlurHandler('channel', formProps)}
                      loading={loaders['channel']}
                      disabled={loaders['channel']}
                    />
                  </BorderedContainer>
                </FigmaBox>
              </FigmaBox>
              <BorderedContainer fullWidth>
                <FigmaBox fullWidth spacing={Spacings.medium} bottom>
                  <TextContainer textKey={TextKeys.cardPartnerHeading} />
                </FigmaBox>
                <FigmaBox fullWidth align="center" direction="row">
                  <FigmaBox fullWidth>
                    <MaterialSelectFieldFormik
                      formProps={formProps}
                      fullWidth
                      name="cardPartner"
                      options={sortedCardPartnerValues.map((value) => ({
                        label: value,
                        value
                      }))}
                      labelTextKey={TextKeys.cardPartnerLabel}
                      onChange={createSelectChangeHandler('cardPartner', formProps)}
                      loading={loaders['cardPartner']}
                      disabled={loaders['cardPartner'] || customer.partnerDisconnected || !permissions.cardPartner}
                    />
                  </FigmaBox>
                  <FigmaBox fullWidth spacing={Spacings.medium} left>
                    <StyledMaterialFieldFormik
                      noSpacing
                      formProps={formProps}
                      fullWidth
                      disabled={customer.cardPartner === CardPartner.NONE || loaders['cardPartnerId'] || !permissions.cardPartner}
                      name="cardPartnerId"
                      labelTextKey={TextKeys.companyIDLabel}
                      onBlur={createBlurHandler('cardPartnerId', formProps)}
                      loading={loaders['cardPartnerId']}
                    />
                  </FigmaBox>
                  <FigmaBox fullWidth spacing={Spacings.medium} left>
                    <MyntPopper
                      visible={showTokenPopper}
                      noPadding
                      marginBottom={Spacings.large}
                      position="top"
                      anchorRef={tokenElementRef.current}
                      content={
                        <ModalConfirmDialog
                          onCancel={() => handleEditTokenDiscard(formProps)}
                          onConfirm={() => handleEditTokenSave(formProps)}
                          description={TextKeys.toolTipAreYouSureTextCompanyToken}
                        />
                      }
                    />
                    <FigmaBox fullWidth ref={tokenElementRef}>
                      <StyledMaterialFieldFormik
                        inputProps={{ ref: tokenRef }}
                        noSpacing
                        formProps={formProps}
                        fullWidth
                        name="cardPartnerToken"
                        disabled={!canEditToken || customer.partnerDisconnected || !permissions.cardPartner}
                        onBlur={() => setShowTokenPopper(true)}
                        labelTextKey={
                          formProps.values.cardPartnerToken ? TextKeys.companyTokenLabel : TextKeys.cardProductToolTipActiveStatus
                        }
                      />
                    </FigmaBox>
                  </FigmaBox>
                  <LinkButton
                    disabled={customer.cardPartner === CardPartner.NONE || customer.partnerDisconnected || !permissions.cardPartner}
                    onClick={handleEditCompanyToken}
                    spacing={Spacings.min}
                    left
                    textKey={TextKeys.companyTokenEdit}
                  />
                </FigmaBox>
              </BorderedContainer>
            </FigmaBox>
            <FigmaBox spacing={Spacings.medium} left style={{ width: '100%', maxWidth: '40%' }}>
              <BorderedContainer green={formProps.values.cardProduct} fullWidth>
                <FigmaBox spacing={Spacings.small} bottom direction="row" align="center" justify="space-between" fullWidth>
                  <TextContainer textKey={TextKeys.cardProduct} />
                  <MaterialSwitchFormik
                    formProps={formProps}
                    name="cardProduct"
                    disabled={loaders['cardProduct']}
                    onChange={createBlurHandler('cardProduct', formProps)}
                  />
                </FigmaBox>
                <FigmaBox spacing={Spacings.min} bottom>
                  <TextContainer textKey={TextKeys.cardFeatuersLabel} />
                </FigmaBox>
                <FigmaBox direction="row" align="center" justify="space-between" fullWidth>
                  <TextContainer textKey={TextKeys.orderCard} />
                  <MaterialSwitchFormik
                    formProps={formProps}
                    name="orderCard"
                    disabled={loaders['orderCard']}
                    onChange={createBlurHandler('orderCard', formProps)}
                  />
                </FigmaBox>
                <Divider spacing={Spacings.min} />
                <FigmaBox direction="row" align="center" justify="space-between" fullWidth>
                  <TextContainer textKey={TextKeys.makeTopUps} />
                  <MaterialSwitchFormik
                    formProps={formProps}
                    name="cardTopup"
                    disabled={loaders['cardTopup']}
                    onChange={createBlurHandler('cardTopup', formProps)}
                  />
                </FigmaBox>
                <Divider spacing={Spacings.min} />
                <FigmaBox direction="row" align="center" justify="space-between" fullWidth>
                  <TextContainer textKey={TextKeys.cardCreditProduct} />
                  <MaterialSwitchFormik
                    formProps={formProps}
                    name="cardCredit"
                    disabled={loaders['cardCredit']}
                    onChange={createBlurHandler('cardCredit', formProps)}
                  />
                </FigmaBox>
                {config.enableSendWelcomeEmail && (
                  <>
                    <Divider spacing={Spacings.min} />
                    <FigmaBox fullWidth direction="row" align="center" justify="space-between" top spacing={Spacings.small}>
                      <TextContainer textKey={TextKeys.cardCreditProductSendWelcomeEmail} />
                      <MyntPopper
                        visible={showSendWelcomeEmailPopper}
                        noPadding
                        position="top"
                        marginBottom={Spacings.medium}
                        anchorRef={sendWelcomeEmailElementRef.current}
                        content={
                          <SendWelcomeEmailPopperContent
                            onConfirm={handleSendWelcomeEmail}
                            onCancel={() => setShowSendWelcomeEmailPopper(false)}
                          />
                        }
                      />
                      <FigmaBox
                        align="center"
                        style={{ cursor: 'pointer' }}
                        ref={sendWelcomeEmailElementRef}
                        onClick={() => setShowSendWelcomeEmailPopper((prev) => !prev)}
                      >
                        <SVGContainer SVG={iconButtonEmail} />
                      </FigmaBox>
                    </FigmaBox>
                  </>
                )}
              </BorderedContainer>
              <BorderedContainer green={formProps.values.companyCredit} fullWidth>
                <FigmaBox spacing={Spacings.large} bottom direction="row" align="center" justify="space-between" fullWidth>
                  <TextContainer textKey={TextKeys.companyCreditProduct} />
                  <MaterialSwitchFormik
                    formProps={formProps}
                    name="companyCredit"
                    disabled={loaders['companyCredit']}
                    onChange={createBlurHandler('companyCredit', formProps)}
                  />
                </FigmaBox>
                <FigmaBox spacing={Spacings.large} bottom fullWidth>
                  <MaterialSelectFieldFormik
                    fullWidth
                    name="companyCreditApprovalStatus"
                    formProps={formProps}
                    labelTextKey={TextKeys.approvalStatusLabel}
                    options={sortedApprovalStatus.map((value) => ({
                      label: value,
                      value
                    }))}
                    onChange={createSelectChangeHandler('companyCreditApprovalStatus', formProps)}
                    loading={loaders['companyCreditApprovalStatus']}
                    disabled={loaders['companyCreditApprovalStatus']}
                  />
                </FigmaBox>
                {formProps.values.companyCreditApprovalExpirationDate && (
                  <FigmaBox spacing={Spacings.large} bottom fullWidth>
                    <MaterialDatePickerFormik
                      fullWidth
                      formProps={formProps}
                      labelTextKey={TextKeys.approvalStatusLabel}
                      labelText={`Approval Expiration Date (${dayjs(formProps.values.companyCreditApprovalExpirationDate).diff(
                        Date.now(),
                        'days'
                      )} days)`}
                      name="companyCreditApprovalExpirationDate"
                      disabled
                    />
                  </FigmaBox>
                )}
                <FigmaBox spacing={Spacings.small} bottom fullWidth>
                  <MaterialNumberFormatFormik
                    adornment={customer.companyCreditLimit.currency.toUpperCase()}
                    formProps={formProps}
                    fullWidth
                    name="companyCreditLimit"
                    labelTextKey={TextKeys.creditLimitLabel}
                    onBlur={createBlurHandler('companyCreditLimit', formProps)}
                    loading={loaders['companyCreditLimit']}
                    disabled={loaders['companyCreditLimit']}
                    error={formProps.errors.companyCreditLimit}
                  />
                </FigmaBox>
                <FigmaBox spacing={Spacings.min} bottom>
                  <TextContainer textKey={TextKeys.companyCreditFeaturesLabel} />
                </FigmaBox>
                <FigmaBox spacing={Spacings.small} direction="row" align="center" justify="space-between" fullWidth>
                  <FigmaBox spacing={Spacings.smallest} bottom>
                    <TextContainer textKey={TextKeys.reclaimCredit} />
                  </FigmaBox>
                  <MaterialSwitchFormik
                    formProps={formProps}
                    name="companyCreditReclaimCredit"
                    disabled={loaders['companyCreditReclaimCredit']}
                    onChange={createBlurHandler('companyCreditReclaimCredit', formProps)}
                  />
                </FigmaBox>
              </BorderedContainer>
              <BorderedContainer align="center" justify="center" fullWidth direction="row">
                <FigmaBox fullWidth>
                  <TextContainer textKey={TextKeys.activationGuideLabel} text="Subscription" />
                </FigmaBox>
                <MaterialSelectFieldFormik
                  fullWidth
                  name="subscription"
                  formProps={formProps}
                  labelTextKey={TextKeys.approvalStatusLabel}
                  labelText="Pricing plan"
                  options={subscriptonPlanValues.map((value) => ({
                    label: value,
                    value
                  }))}
                  loading={loaders['subscription']}
                  disabled
                />
              </BorderedContainer>
              <BorderedContainer align="center" justify="center" fullWidth direction="row">
                <FigmaBox fullWidth>
                  <TextContainer textKey={TextKeys.activationGuideLabel} />
                </FigmaBox>
                <MaterialSelectFieldFormik
                  fullWidth
                  name="visibility"
                  formProps={formProps}
                  labelTextKey={TextKeys.approvalStatusLabel}
                  labelText="Visibility"
                  options={sortedActivationGuideValue.map((value) => ({
                    label: value,
                    value
                  }))}
                  onChange={handleActivationGuideStatus('visibility', formProps)}
                  loading={loaders['visibility']}
                  disabled={loaders['visibility'] || !permissions.activationGuide}
                />
              </BorderedContainer>
              <Access permissions="customers.delete">
                <BorderedContainer direction="row" align="center" justify="space-between" fullWidth>
                  <TextContainer textKey={TextKeys.deleteCustomerLabel} />
                  <MyntPopper
                    visible={showDeleteCustomerPopper}
                    noPadding
                    marginBottom={Spacings.large}
                    position="top"
                    anchorRef={deleteCustomerElementRef.current}
                    content={
                      <DeleteCustomerPopper onConfirm={() => handleCustomerDelete()} onCancel={() => setShowDeleteCustomerPopper(false)} />
                    }
                  />
                  <FigmaBox
                    align="center"
                    style={{ cursor: 'pointer' }}
                    ref={deleteCustomerElementRef}
                    onClick={() => setShowDeleteCustomerPopper(!showDeleteCustomerPopper)}
                  >
                    <DeleteCustomerIcon />
                  </FigmaBox>
                </BorderedContainer>
              </Access>
            </FigmaBox>
          </FigmaBox>
        )}
      </Formik>
    </FigmaBox>
  )
}

export default CustomerGeneralForm
