import { ModalActivateCard } from '../../components/Modals.tsx'
import {
  usePatchCard,
  usePatchCardConfig,
  usePrivateCustomerCardConfig,
  useReissueCard,
  useResetCardPin,
  useSyncCards
} from 'api/react-query'
import FigmaBox from 'mynt-components/components/FigmaBox.tsx'
import ImageContainer from 'mynt-components/components/ImageContainer.tsx'
import React, { useEffect, useRef, useState } from 'react'
import { ImageKeys } from 'tiger/libs/ImageRepository.ts'
import { CardStatusPatch, VisaCardResponse, ListPlasticResponse, CardStatus } from '../../api/swagger/definitions/backoffice'

import Box from '@mui/material/Box'
import { useForm } from 'react-hook-form'
import { SelectController, SwitchController, TextFieldController } from '../../components/react-hook-components.tsx'
import Typography from '@mui/material/Typography'
import { DataGridPro } from '@mui/x-data-grid-pro'
import useMaterialNotification from 'hooks/useMaterialNotification.tsx'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'
import { isEqual } from 'lodash'

interface CustomerCardTabOverviewSingleCardDetailsPrivateCardProps {
  card?: VisaCardResponse
  plastics?: ListPlasticResponse[]
  customerId: string
  isLoading?: boolean
}

type ClickEvent = React.MouseEvent<HTMLDivElement, MouseEvent>

type ActivateCardProps = {
  customerId: string
  onShow?: () => void
  onClose?: () => void
  showModal: boolean
  plastic: ListPlasticResponse
}

const ActivateCard: React.FC<ActivateCardProps> = (props) => {
  const { showModal, onShow, ...modalProps } = props
  const [activateModal, setActivateModal] = useState(false)
  const anchor = useRef<HTMLDivElement>(null)

  const handleClick = (event: ClickEvent) => {
    event.persist()
    setActivateModal(true)
    onShow?.()
  }

  const handleClose = () => {
    setActivateModal(false)
  }

  return (
    <>
      {activateModal && showModal && <ModalActivateCard {...modalProps} anchor={anchor.current} onClose={handleClose} />}
      <FigmaBox onClick={handleClick} justify="center" align="center" fullWidth>
        <div onClick={() => null} />

        <FigmaBox ref={anchor}>
          <ImageContainer imageKey={ImageKeys.activateCardIcon} />
        </FigmaBox>
      </FigmaBox>
    </>
  )
}

type FormValues = {
  status: CardStatus | undefined
  limit: string | undefined
  allowAtm: boolean
}

const CustomerCardTabOverviewSingleCardDetailsPrivateCard: React.FC<CustomerCardTabOverviewSingleCardDetailsPrivateCardProps> = ({
  card,
  plastics,
  customerId,
  isLoading
}) => {
  const [loaders, _setLoaders] = useState({
    status: false,
    limit: false,
    atm: false,
    sync: false,
    resetLimit: false,
    reissue: false,
    resetPinAttempts: false
  })

  const setLoader = (loader: keyof typeof loaders, value = true) => _setLoaders((loaders) => ({ ...loaders, [loader]: value }))

  const [currentCardActivate, setCurrentCardActivate] = useState<string>()
  const cardId = card?.id || ''

  const cardMutation = usePatchCard(customerId, cardId)
  const cardConfigMutation = usePatchCardConfig(customerId, cardId)
  const reissueCardMutation = useReissueCard(customerId)
  const resetCardPainMutation = useResetCardPin(customerId)
  const syncMutation = useSyncCards(customerId)

  const notify = useMaterialNotification()

  const { data: cardConfig } = usePrivateCustomerCardConfig(customerId, cardId)

  const form = useForm({
    defaultValues: {
      status: card?.status ?? ('' as CardStatus),
      limit: String(cardConfig?.monthlyLimit ?? ''),
      allowAtm: Boolean(cardConfig?.allowAtm)
    },
    mode: 'onBlur'
  })

  useEffect(() => {
    if (!card || !cardConfig) return

    form.reset({
      status: card.status,
      limit: String(cardConfig.monthlyLimit ?? ''),
      allowAtm: Boolean(cardConfig.allowAtm)
    })
  }, [card, cardConfig])

  const handleCardConfigSubmit = (loader: keyof typeof loaders) => async (data: FormValues) => {
    const payload = {
      monthlyLimit: data.limit === '' ? null : Number(data.limit),
      allowAtm: data.allowAtm
    }

    const dataIsEqual = isEqual(payload, {
      monthlyLimit: cardConfig?.monthlyLimit,
      allowAtm: cardConfig?.allowAtm
    })

    if (dataIsEqual) return

    setLoader(loader)

    await cardConfigMutation.mutateAsync(payload).finally(() => {
      setLoader(loader, false)
    })

    notify(`Updated card config`)
  }

  const handleOnSubmit = (loader: keyof typeof loaders) => async (data: FormValues) => {
    if (!data.status || data.status === card?.status) return

    const status = data.status as CardStatusPatch

    setLoader(loader)

    await cardMutation
      .mutateAsync({
        status
      })
      .finally(() => {
        setLoader(loader, false)
      })

    notify(`Updated card status`)
  }

  const handleResetLimit = async () => {
    setLoader('resetLimit')

    await cardConfigMutation
      .mutateAsync({
        monthlyLimitActive: false
      })
      .finally(() => {
        setLoader('resetLimit', false)
      })

    notify(`Card limit has been reset`)
  }

  const handleReissueCard = async () => {
    setLoader('reissue')

    await reissueCardMutation.mutateAsync(cardId).finally(() => {
      setLoader('reissue', false)
    })

    notify(`Card has been reissued`)
  }

  const handleResetPin = async () => {
    setLoader('resetPinAttempts')

    await resetCardPainMutation.mutateAsync(cardId).finally(() => {
      setLoader('resetPinAttempts', false)
    })

    notify(`Card PIN attempts have been reset`)
  }

  const notifyAfterPromise = (message: string) => () => notify(message)

  const loadingAdornment = isLoading ? (
    <Box sx={{ paddingRight: '16px' }}>
      <CircularProgress size="20px" />
    </Box>
  ) : null

  const statusPatchOptions = [
    CardStatusPatch.CARD_OK,
    CardStatusPatch.CARD_BLOCKED,
    CardStatusPatch.SUSPECTED_FRAUD,
    CardStatusPatch.CARD_CLOSED
  ].map((status) => ({ label: status, value: status }))

  return (
    <Box sx={{ display: 'flex', gap: '32px', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', gap: '16px', alignItems: 'baseline', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <SelectController
            disabled={loaders.status || isLoading}
            onBlur={form.handleSubmit(handleOnSubmit('status'))}
            control={form.control}
            name="status"
            labelText="Status"
            options={statusPatchOptions}
          />
          <TextFieldController
            disabled={loaders.limit || isLoading}
            onBlur={form.handleSubmit(handleCardConfigSubmit('limit'))}
            control={form.control}
            name="limit"
            labelText="Limit"
            InputProps={{ endAdornment: loadingAdornment }}
          />
          <SwitchController
            disabled={cardConfigMutation.isLoading || isLoading}
            onBlur={form.handleSubmit(handleCardConfigSubmit('atm'))}
            control={form.control}
            name="allowAtm"
            labelText={<Box sx={{ paddingLeft: '8px' }}>ATM</Box>}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'baseline', flexWrap: 'wrap' }}>
          <LoadingButton
            variant="secondary"
            onClick={() => syncMutation.mutateAsync(card?.id as string).then(notifyAfterPromise('Synced card'))}
            loading={syncMutation.isLoading}
          >
            Sync
          </LoadingButton>
          <LoadingButton variant="secondary" onClick={handleResetLimit} loading={loaders.resetLimit}>
            Reset limit
          </LoadingButton>
          <LoadingButton variant="secondary" onClick={handleReissueCard} loading={loaders.reissue}>
            Reissue
          </LoadingButton>
          <LoadingButton variant="secondary" onClick={handleResetPin} loading={loaders.resetPinAttempts}>
            Reset PIN attempts
          </LoadingButton>
        </Box>
      </Box>
      {card && (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '32px', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography variant="body2">Card number: {card.maskedCardNumber}</Typography>
            <Typography variant="body2">
              Expiration: {card.expiration?.year}/{card.expiration?.month}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography variant="body2">Card role: {card.cardRole}</Typography>
            <Typography variant="body2">Reason: {card.reason}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <Typography variant="body2">Product code: {card.productCode}</Typography>
            <Typography variant="body2">Company name: {card.embossing?.companyName}</Typography>
          </Box>
        </Box>
      )}
      <Box>
        <Typography variant="h2">Plastics</Typography>
        <Box sx={{ height: isLoading ? 200 : 'auto', paddingTop: '16px' }}>
          <DataGridPro
            loading={isLoading}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            disableColumnPinning
            disableColumnReorder
            disableColumnResize
            rows={plastics || []}
            slots={{
              noRowsOverlay: () => (
                <Stack height="100%" alignItems="center" justifyContent="center">
                  No plastics found
                </Stack>
              ),
              loadingOverlay: LinearProgress
            }}
            columns={[
              {
                field: 'firstName',
                headerName: 'First name',
                flex: 1,
                sortable: false
              },
              {
                field: 'lastName',
                headerName: 'Last name',
                flex: 1,
                sortable: false
              },
              {
                field: 'companyName',
                headerName: 'Company',
                flex: 1,
                sortable: false
              },
              {
                field: 'createdDate',
                headerName: 'Created date',
                flex: 1,
                sortable: false
              },
              {
                field: 'productionDate',
                headerName: 'Production date',
                flex: 1.25,
                sortable: false
              },
              {
                field: 'productionType',
                headerName: 'Production type',
                flex: 1.25,
                sortable: false
              },
              {
                field: 'expiration',
                headerName: 'Expiration',
                valueGetter: (params: any) => `${params.row.expiration.month}/${params.row.expiration.year}`,
                flex: 1,
                sortable: false
              },
              {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                sortable: false
              },
              {
                field: 'activateCard',
                headerName: 'Activate Card',
                flex: 1.25,
                sortable: false,
                renderCell: (params) => (
                  <ActivateCard
                    customerId={customerId}
                    onShow={() => setCurrentCardActivate(params.row.id)}
                    showModal={params.row.id === currentCardActivate}
                    onClose={() => setCurrentCardActivate(undefined)}
                    plastic={params.row}
                  />
                )
              }
            ]}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CustomerCardTabOverviewSingleCardDetailsPrivateCard
