import { useBillingSettings } from 'api/react-query'
import MyntLoader from 'mynt-components/components/MyntLoader'
import { BillingSettingsForm } from './BillingSettingsForm'
import Box from '@mui/material/Box'
import TextContainer from 'components/TextContainer'
import TextKeys from 'libs/TextKeys'

const BillingSettingsV2 = ({ customerId }: { customerId: string }) => {
  const { data: billingSettings, isLoading: isLoadingBillingSettings, error: billingSettingsError } = useBillingSettings(customerId)

  if (isLoadingBillingSettings) {
    return <MyntLoader />
  }

  if (billingSettingsError || (!billingSettings && !isLoadingBillingSettings)) {
    return <h1>{'Something went wrong (:'}</h1>
  }

  return (
    <Box>
      <TextContainer textKey={TextKeys.billingSettingsHeading} />
      <BillingSettingsForm billingSettings={billingSettings} customerId={customerId} />
    </Box>
  )
}

export default BillingSettingsV2
