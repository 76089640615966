import styled from '@emotion/styled'
import { useFormContext } from 'react-hook-form'
import { default as IconRemoveOverride } from 'figma/images/iconRemoveOverride'
import Colors from 'figma/panda/Colors'
import { BorderedContainer } from 'helpers/customerGeneralFormHelper'
import TextKeys from 'libs/TextKeys'
import { UnstyledButton } from 'mynt-components/components/StyledComponents'
import TextContainer from 'components/TextContainer'
import { TooltipResetAllOverrides } from 'components/Tooltips'
import { SelectController } from 'components/react-hook-components'
import { ChangeEvent } from 'react'
import type { BillingSettingsFormData } from './BillingSettingsForm'
import { BillingSettingsPricePlan } from 'api/swagger/definitions/backoffice'
import { useUpdateBillingSettings } from 'api/react-query'
import { SettingsContainer } from 'pages/CustomerCardTab/BillingSettings/SettingsContainer'
import Box from '@mui/material/Box'

type PricingPlanProps = {
  handleResetOverrides: () => void
  isLoading: boolean
  customerId: string
}
export const PricingPlan = ({ handleResetOverrides, isLoading, customerId }: PricingPlanProps) => {
  const { control } = useFormContext<BillingSettingsFormData>()

  const { mutateAsync, isLoading: isUpdatingPricePlan } = useUpdateBillingSettings(customerId)

  const handleUpdatePricingPlan = async (data: BillingSettingsPricePlan) => {
    await mutateAsync({ pricePlan: data })
  }

  const onChangePricingPlan = async (event: ChangeEvent<HTMLSelectElement>) => {
    const pricePlan: BillingSettingsPricePlan = BillingSettingsPricePlan[event.target.value] ?? undefined
    if (pricePlan) {
      await handleUpdatePricingPlan(pricePlan)
    }
  }

  return (
    <SettingsContainer
      title={TextKeys.billingSettingsPricingPlanHeading}
      bgColor={Colors.backgroundColorsCloud}
      action={
        <TooltipResetAllOverrides
          content={
            <UnstyledButton onClick={() => handleResetOverrides()}>
              <IconRemoveOverride />
            </UnstyledButton>
          }
        />
      }
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: 3
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <TextContainer textKey={TextKeys.billingSettingsPricingPlanSubscriptionPlan} />
        </Box>
        <SelectController
          control={control}
          name="payload.pricePlan"
          noLabel
          options={Object.values(BillingSettingsPricePlan).map((plan) => ({
            label: plan,
            value: plan
          }))}
          onChange={(e) => onChangePricingPlan(e)}
          loading={isLoading || isUpdatingPricePlan}
          disabled={isLoading || isUpdatingPricePlan}
          inheritBackgroundColor={false}
        />
      </Box>
    </SettingsContainer>
  )
}

export default PricingPlan
