import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import {
  type BillingSettings,
  BillingSettingsCardGroup,
  BillingSettingsFieldName,
  BillingSettingsOverridePatch,
  BillingSettingsPricePlan
} from 'api/swagger/definitions/backoffice'
import Box from '@mui/material/Box'
import { useEffect, useRef, useState } from 'react'
import FeatureSettings from './FeatureSettings'
import OtherFees from './OtherFees'
import SubscriptionFees from './SubscriptionFees'
import PricingPlan from './PricingPlan'
import UsersSettings from './UsersSettings'
import { ModalAreYouSureWithBody } from 'components/Modals'
import TextKeys from 'libs/TextKeys'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js'
import { useResetBillingSetting, useUpdateBillingSettings } from 'api/react-query'

export type BillingSettingsFormData = {
  payload: BillingSettingsOverridePatch
} & BillingSettings

type BillingSettingsFormProps = {
  billingSettings: BillingSettings
  customerId: string
}

export const BillingSettingsForm = ({ billingSettings, customerId }: BillingSettingsFormProps) => {
  const [confirmAllReset, setConfirmAllReset] = useState(false)
  const anchor = useRef<HTMLDivElement | null>(null)

  const { mutateAsync: mutateBillingSettings, isLoading: isUpdatingBillingSettings } = useUpdateBillingSettings(customerId)
  const { mutateAsync: resetOverrides, isLoading: isResetingOverrides } = useResetBillingSetting(customerId)

  const form = useForm<BillingSettingsFormData>({
    defaultValues: {
      ...billingSettings,
      payload: getPayloadData(billingSettings)
    },
    resolver: yupResolver(formSchema)
  })

  useEffect(() => {
    if (billingSettings) {
      form.reset(
        {
          ...billingSettings,
          payload: getPayloadData(billingSettings)
        },
        { keepDirtyValues: false, keepDefaultValues: false }
      )
    }
  }, [billingSettings])

  const handleResetField = async (field: BillingSettingsFieldName) => {
    await resetOverrides(field)
  }

  const handleSubmit = async (data: BillingSettingsOverridePatch) => {
    await mutateBillingSettings(data)
  }

  return (
    <FormProvider {...form}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          gap: 3,
          flexDirection: {
            xs: 'column',
            lg: 'row'
          }
        }}
      >
        {confirmAllReset && (
          <ModalAreYouSureWithBody
            bodyText={TextKeys.billingSettingsModalTextResetAllValues}
            onClose={() => setConfirmAllReset(false)}
            onContinue={async () => {
              await handleResetField('ALL')
              setConfirmAllReset(false)
            }}
            anchor={anchor.current}
          />
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1
          }}
        >
          <PricingPlan
            handleResetOverrides={() => setConfirmAllReset(true)}
            isLoading={isUpdatingBillingSettings || isResetingOverrides}
            customerId={customerId}
          />
          <SubscriptionFees
            isLoading={isUpdatingBillingSettings || isResetingOverrides}
            handleUpdate={(subscriptionFee) => handleSubmit({ subscriptionFee })}
            handleReset={handleResetField}
          />
          <OtherFees
            isLoading={isUpdatingBillingSettings || isResetingOverrides}
            handleChange={(cardGroup) => handleSubmit({ cardGroup })}
            handleReset={handleResetField}
          />
          <UsersSettings
            isLoading={isUpdatingBillingSettings || isResetingOverrides}
            customerId={customerId}
            handleReset={handleResetField}
            handleUpdate={(userSettings) => handleSubmit(userSettings)}
          />
        </Box>
        <FeatureSettings anchor={anchor} isLoading={isUpdatingBillingSettings || isResetingOverrides} customerId={customerId} />
      </Box>
    </FormProvider>
  )
}

const moreThanZeroSchema = Yup.number().required().min(0, 'Must be at least 0').typeError('Please enter a valid number')

const formSchema = Yup.object().shape({
  payload: Yup.object().shape({
    pricePlan: Yup.mixed().oneOf(Object.values(BillingSettingsPricePlan)).required(),
    pricePerAdditionalUser: Yup.number().when('pricePlan', {
      is: (planType: BillingSettingsPricePlan) => planType === 'PREMIUM',
      then: moreThanZeroSchema,
      otherwise: (schema) => schema.notRequired().nullable()
    }),
    numberOfUsers: moreThanZeroSchema,
    cardGroup: Yup.mixed().oneOf(Object.values(BillingSettingsCardGroup)).required(),
    subscriptionFee: moreThanZeroSchema,
    isUnlimitedUsers: Yup.boolean().required()
  })
})

const getPayloadData = (billingSettings: BillingSettings) => ({
  pricePlan: billingSettings.pricePlanType,
  cardGroup: billingSettings.fee.cardGroup.value,
  subscriptionFee: billingSettings.fee.amount.value.value,
  numberOfUsers: 'numberOfIncludedUsers' in billingSettings.userSettings ? billingSettings.userSettings.numberOfIncludedUsers.value : 0,
  pricePerAdditionalUser:
    'pricePerAdditionalUser' in billingSettings.userSettings ? billingSettings.userSettings.pricePerAdditionalUser.value.value : 0,
  isUnlimitedUsers: billingSettings.userSettings.type === 'UNLIMITED_USERS'
})
