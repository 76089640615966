import { useFormContext } from 'react-hook-form'
import { BillingSettingsCardGroup, BillingSettingsFieldName } from 'api/swagger/definitions/backoffice'
import TextKeys from 'libs/TextKeys'
import TextContainer from 'components/TextContainer'
import { SelectController } from 'components/react-hook-components'
import { ChangeEvent } from 'react'
import type { BillingSettingsFormData } from './BillingSettingsForm'
import { OverrideSettingComponent } from './OverrideSettingComponent'
import { SettingsContainer } from './SettingsContainer'
import Box from '@mui/material/Box'

type OtherFeesProps = {
  isLoading: boolean
  handleReset: (field: BillingSettingsFieldName) => void
  handleChange: (data: BillingSettingsCardGroup) => void
}
const OtherFees = ({ isLoading, handleReset, handleChange }: OtherFeesProps) => {
  const { watch, control } = useFormContext<BillingSettingsFormData>()
  const groupLabel = (group?: BillingSettingsCardGroup) => {
    switch (group) {
      case BillingSettingsCardGroup.A:
        return '0%'
      case BillingSettingsCardGroup.B:
        return '1.5%'
      case BillingSettingsCardGroup.C:
        return '1.95%'
      case BillingSettingsCardGroup.D:
        return '0.2%'
      case BillingSettingsCardGroup.E:
        return '0.5%'
      case BillingSettingsCardGroup.F:
        return '1.0%'
      default:
        return '0%'
    }
  }

  const groupOptions = Object.values(BillingSettingsCardGroup).map((group) => ({
    label: groupLabel(group),
    value: group
  }))

  const onChangeFxRate = (e: ChangeEvent<HTMLSelectElement>) => {
    const fxRate = BillingSettingsCardGroup[e.target.value]
    if (fxRate) {
      if (fxRate === watch('fee.cardGroup.originalValue')) {
        handleReset('CARD_GROUP')
      } else {
        handleChange(fxRate)
      }
    }
  }

  const defaultCardGroup = watch('fee.cardGroup.originalValue')
  const isOverridden = watch('fee.cardGroup.isOverride')

  return (
    <SettingsContainer title={TextKeys.billingSettingsOtherFeesHeading}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: 3
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <TextContainer textKey={TextKeys.billingSettingsOtherFeesFxSurcharge} />
          {isOverridden && (
            <OverrideSettingComponent defaultValue={groupLabel(defaultCardGroup)} onClickResetOverride={() => handleReset('CARD_GROUP')} />
          )}
        </Box>
        <SelectController
          loading={isLoading}
          disabled={isLoading}
          control={control}
          name="payload.cardGroup"
          labelTextKey={TextKeys.billingSettingsOtherFeesFxSurcharge}
          options={groupOptions}
          onChange={onChangeFxRate}
        />
      </Box>
    </SettingsContainer>
  )
}

export default OtherFees
