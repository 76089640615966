import { TooltipResetOverride, TooltipValueOverridden } from 'components/Tooltips'
import { UnstyledButton } from 'mynt-components/components/StyledComponents'
import React, { useRef, useState } from 'react'
import IconAlertInfoYellow from 'figma/images/iconAlertInfoYellow'
import IconRemoveOverride from 'figma/images/iconRemoveOverride'
import { ModalAreYouSureWithBody } from 'components/Modals'
import TextKeys from 'libs/TextKeys'
import Box from '@mui/material/Box'

type OverrideSettingProps = {
  defaultValue: string
  onClickResetOverride: () => void
}

export const OverrideSettingComponent = ({ defaultValue, onClickResetOverride }: OverrideSettingProps) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const anchor = useRef<HTMLDivElement | null>(null)

  return (
    <>
      {confirmationModalOpen && (
        <ModalAreYouSureWithBody
          bodyText={TextKeys.billingSettingsModalTextResetSubscriptionFee}
          onClose={() => setConfirmationModalOpen(false)}
          onContinue={() => {
            setConfirmationModalOpen(false)

            onClickResetOverride()
          }}
          anchor={anchor.current}
        />
      )}
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <TooltipValueOverridden
          defaultValue={defaultValue}
          content={
            <UnstyledButton>
              <IconAlertInfoYellow aria-label="alert-icon" />
            </UnstyledButton>
          }
        />
        <TooltipResetOverride
          content={
            <UnstyledButton onClick={() => setConfirmationModalOpen(true)}>
              <IconRemoveOverride aria-label="reset-override" />
            </UnstyledButton>
          }
        />
      </Box>
    </>
  )
}
